/**
 * @fileoverview gRPC-Web generated client stub for dibric.portal.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.19.1
// source: portal/v1/workspace_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var portal_v1_extension_service_pb = require('../../portal/v1/extension_service_pb.js')

var graph_v1_public_messages_pb = require('../../graph/v1/public_messages_pb.js')
const proto = {};
proto.dibric = {};
proto.dibric.portal = {};
proto.dibric.portal.v1 = require('./workspace_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.WorkspaceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.CreateWorkspaceRequest,
 *   !proto.dibric.portal.v1.CreateWorkspaceResponse>}
 */
const methodDescriptor_WorkspaceService_Create = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/Create',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.CreateWorkspaceRequest,
  proto.dibric.portal.v1.CreateWorkspaceResponse,
  /**
   * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.CreateWorkspaceResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.CreateWorkspaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.CreateWorkspaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/Create',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_Create,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.CreateWorkspaceResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/Create',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.GetWorkspaceRequest,
 *   !proto.dibric.portal.v1.GetWorkspaceResponse>}
 */
const methodDescriptor_WorkspaceService_Get = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/Get',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.GetWorkspaceRequest,
  proto.dibric.portal.v1.GetWorkspaceResponse,
  /**
   * @param {!proto.dibric.portal.v1.GetWorkspaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.GetWorkspaceResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.GetWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.GetWorkspaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.GetWorkspaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/Get',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_Get,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.GetWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.GetWorkspaceResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/Get',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.DeleteWorkspaceRequest,
 *   !proto.dibric.portal.v1.DeleteWorkspaceResponse>}
 */
const methodDescriptor_WorkspaceService_Delete = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/Delete',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.DeleteWorkspaceRequest,
  proto.dibric.portal.v1.DeleteWorkspaceResponse,
  /**
   * @param {!proto.dibric.portal.v1.DeleteWorkspaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.DeleteWorkspaceResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.DeleteWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.DeleteWorkspaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.DeleteWorkspaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/Delete',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_Delete,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.DeleteWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.DeleteWorkspaceResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/Delete',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.UpdateWorkspaceRequest,
 *   !proto.dibric.portal.v1.UpdateWorkspaceResponse>}
 */
const methodDescriptor_WorkspaceService_Update = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/Update',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.UpdateWorkspaceRequest,
  proto.dibric.portal.v1.UpdateWorkspaceResponse,
  /**
   * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.UpdateWorkspaceResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.UpdateWorkspaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.UpdateWorkspaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/Update',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_Update,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.UpdateWorkspaceResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/Update',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.ListWorkspaceRequest,
 *   !proto.dibric.portal.v1.ListWorkspaceResponse>}
 */
const methodDescriptor_WorkspaceService_List = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/List',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.ListWorkspaceRequest,
  proto.dibric.portal.v1.ListWorkspaceResponse,
  /**
   * @param {!proto.dibric.portal.v1.ListWorkspaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.ListWorkspaceResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.ListWorkspaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.ListWorkspaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/List',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_List,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.ListWorkspaceResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/List',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.ListWorkspaceItemRequest,
 *   !proto.dibric.portal.v1.ListWorkspaceItemResponse>}
 */
const methodDescriptor_WorkspaceService_ListItems = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/ListItems',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.ListWorkspaceItemRequest,
  proto.dibric.portal.v1.ListWorkspaceItemResponse,
  /**
   * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.ListWorkspaceItemResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.ListWorkspaceItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.ListWorkspaceItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.listItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/ListItems',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_ListItems,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.ListWorkspaceItemResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.listItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/ListItems',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_ListItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.CreateProjectRequest,
 *   !proto.dibric.portal.v1.CreateProjectResponse>}
 */
const methodDescriptor_WorkspaceService_CreateProject = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/CreateProject',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.CreateProjectRequest,
  proto.dibric.portal.v1.CreateProjectResponse,
  /**
   * @param {!proto.dibric.portal.v1.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.CreateProjectResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.CreateProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.CreateProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.createProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/CreateProject',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_CreateProject,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.CreateProjectResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.createProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/CreateProject',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_CreateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.UpdateProjectRequest,
 *   !proto.dibric.portal.v1.UpdateProjectResponse>}
 */
const methodDescriptor_WorkspaceService_UpdateProject = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/UpdateProject',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.UpdateProjectRequest,
  proto.dibric.portal.v1.UpdateProjectResponse,
  /**
   * @param {!proto.dibric.portal.v1.UpdateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.UpdateProjectResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.UpdateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.UpdateProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.UpdateProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.updateProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_UpdateProject,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.UpdateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.UpdateProjectResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.updateProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_UpdateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.DeleteItemRequest,
 *   !proto.dibric.portal.v1.DeleteItemResponse>}
 */
const methodDescriptor_WorkspaceService_DeleteItem = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/DeleteItem',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.DeleteItemRequest,
  proto.dibric.portal.v1.DeleteItemResponse,
  /**
   * @param {!proto.dibric.portal.v1.DeleteItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.DeleteItemResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.DeleteItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.DeleteItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.DeleteItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.deleteItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/DeleteItem',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_DeleteItem,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.DeleteItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.DeleteItemResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.deleteItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/DeleteItem',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_DeleteItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.CopyItemRequest,
 *   !proto.dibric.portal.v1.CopyItemResponse>}
 */
const methodDescriptor_WorkspaceService_CopyItem = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/CopyItem',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.CopyItemRequest,
  proto.dibric.portal.v1.CopyItemResponse,
  /**
   * @param {!proto.dibric.portal.v1.CopyItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.CopyItemResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.CopyItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.CopyItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.CopyItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.copyItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/CopyItem',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_CopyItem,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.CopyItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.CopyItemResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.copyItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/CopyItem',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_CopyItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dibric.portal.v1.GetItemRequest,
 *   !proto.dibric.portal.v1.GetItemResponse>}
 */
const methodDescriptor_WorkspaceService_GetItem = new grpc.web.MethodDescriptor(
  '/dibric.portal.v1.WorkspaceService/GetItem',
  grpc.web.MethodType.UNARY,
  proto.dibric.portal.v1.GetItemRequest,
  proto.dibric.portal.v1.GetItemResponse,
  /**
   * @param {!proto.dibric.portal.v1.GetItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dibric.portal.v1.GetItemResponse.deserializeBinary
);


/**
 * @param {!proto.dibric.portal.v1.GetItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dibric.portal.v1.GetItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dibric.portal.v1.GetItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dibric.portal.v1.WorkspaceServiceClient.prototype.getItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/GetItem',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_GetItem,
      callback);
};


/**
 * @param {!proto.dibric.portal.v1.GetItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dibric.portal.v1.GetItemResponse>}
 *     Promise that resolves to the response
 */
proto.dibric.portal.v1.WorkspaceServicePromiseClient.prototype.getItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dibric.portal.v1.WorkspaceService/GetItem',
      request,
      metadata || {},
      methodDescriptor_WorkspaceService_GetItem);
};


module.exports = proto.dibric.portal.v1;

