import { RpcError } from 'grpc-web';
import { Edge, InputVariable, InputVariables, NodeHandlePosition, NodePosition, OutputVariables, Variable } from '../protos/graph/v1/public_messages_pb';
import { WorkspaceServicePromiseClient } from '../protos/portal/v1/workspace_service_grpc_web_pb';
import {
  CopyItemRequest,
  CopyItemResponse,
  CreateProjectRequest,
  CreateProjectResponse,
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  DeleteItemRequest,
  DeleteItemResponse,
  DeleteWorkspaceRequest,
  DeleteWorkspaceResponse,
  ExtensionTask,
  GetItemRequest,
  GetItemResponse,
  GetWorkspaceRequest,
  GetWorkspaceResponse,
  InputTask,
  ListWorkspaceItemRequest,
  ListWorkspaceItemResponse,
  ListWorkspaceRequest,
  ListWorkspaceResponse,
  NavigationType,
  Node,
  NodeHandle,
  UpdateProjectRequest,
  UpdateProjectResponse,
  UpdateWorkspaceRequest,
  UpdateWorkspaceResponse,
  Workspace,
  WorkspaceItem,
  WorkspaceItemStatus,
  WorkspaceItemSummary
} from '../protos/portal/v1/workspace_service_pb';
import { getDefaultMetadata, handleUnauthorized } from './utils';

const client = new WorkspaceServicePromiseClient(`${window.location.protocol}//${window.location.host}`);

export async function createWorkspace(request: CreateWorkspaceRequest): Promise<CreateWorkspaceResponse> {
  try {
    return await client.create(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function updateWorkspace(request: UpdateWorkspaceRequest): Promise<UpdateWorkspaceResponse> {
  try {
    return await client.update(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function listWorkspace(request: ListWorkspaceRequest): Promise<ListWorkspaceResponse> {
  try {
    return await client.list(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function deleteWorkspace(request: DeleteWorkspaceRequest): Promise<DeleteWorkspaceResponse> {
  try {
    return await client.delete(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function listWorkspaceItems(request: ListWorkspaceItemRequest): Promise<ListWorkspaceItemResponse> {
  try {
    return await client.listItems(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function getWorkspace(request: GetWorkspaceRequest): Promise<GetWorkspaceResponse> {
  try {
    return await client.get(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function createProject(request: CreateProjectRequest): Promise<CreateProjectResponse> {
  try {
    return await client.createProject(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function updateProject(request: UpdateProjectRequest): Promise<UpdateProjectResponse> {
  try {
    return await client.updateProject(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function deleteItem(request: DeleteItemRequest): Promise<DeleteItemResponse> {
  try {
    return await client.deleteItem(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function copyItem(request: CopyItemRequest): Promise<CopyItemResponse> {
  try {
    return await client.copyItem(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export async function getItem(request: GetItemRequest): Promise<GetItemResponse> {
  try {
    return await client.getItem(request, getDefaultMetadata());
  } catch (error: unknown) {
    if (error instanceof RpcError && handleUnauthorized(error)) {
      console.log('Unauthorized. Redirecting to login....');
    }
    throw error;
  }
}

export {
  CopyItemRequest,
  CopyItemResponse,
  CreateProjectRequest,
  CreateProjectResponse,
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  DeleteItemRequest,
  DeleteItemResponse,
  DeleteWorkspaceRequest,
  DeleteWorkspaceResponse,
  Edge,
  ExtensionTask,
  GetItemRequest,
  GetItemResponse,
  GetWorkspaceRequest,
  GetWorkspaceResponse,
  InputTask,
  InputVariable,
  InputVariables,
  ListWorkspaceItemRequest,
  ListWorkspaceItemResponse,
  ListWorkspaceRequest,
  ListWorkspaceResponse,
  NavigationType,
  Node,
  NodeHandle,
  NodeHandlePosition,
  NodePosition,
  OutputVariables,
  UpdateWorkspaceRequest,
  UpdateWorkspaceResponse,
  Variable,
  Workspace,
  WorkspaceItem,
  WorkspaceItemStatus,
  WorkspaceItemSummary
};
