import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import App from './App';
import Workspaces from './workspaces/Workspaces';

export const routes: RouteObject[] = [
  {
    path: 'workspaces/:workspaceId/items/:itemId/run',
    lazy: async () => ({ Component: (await import('./workspaces/execution/RunProject/RunProject')).RunProject }),
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Navigate to="workspaces" replace />,
      },
      {
        path: 'workspaces/:workspaceId',
        lazy: async () => ({ Component: (await import('./workspaces/WorkspaceWrapper')).WorkspaceWrapper }),
        children: [
          {
            path: 'items/:itemId',
            lazy: async () => ({ Component: (await import('./workspaces/editor/EditorContainer')).EditorContainer }),
            children: [
              {
                path: 'settings',
                lazy: async () => ({ Component: (await import('./workspaces/editor/ProjectSettings')).ProjectSettings }),
                children: [
                  {
                    path: 'extensions',
                    lazy: async () => ({ Component: (await import('./workspaces/editor/GlobalConfig')).GlobalConfig }),
                  },
                  {
                    path: 'project',
                    lazy: async () => ({ Component: (await import('./workspaces/editor/ProjectSettingsEditor')).ProjectSettingsEditor }),
                  },
                  {
                    path: '',
                    element: <Navigate to="extensions" replace />,
                  },
                ],
              },
              {
                path: 'design',
                lazy: async () => ({ Component: (await import('./workspaces/editor/ProjectDesigner')).ProjectDesigner }),
              },
              {
                path: '',
                element: <Navigate to="design" replace />,
              },
            ],
          },
          {
            path: 'deployments/create',
            lazy: async () => ({ Component: (await import('./workspaces/deployments/CreateDeploymentSlot')).CreateDeploymentSlot }),
          },
          {
            path: 'deployments/:deploymentId',
            lazy: async () => ({ Component: (await import('./workspaces/deployments/DeploymentSlotDetails')).DeploymentSlotDetails }),
          },
          {
            path: '',
            lazy: async () => ({ Component: (await import('./workspaces/WorkspaceDetails')).WorkspaceDetails }),
            children: [
              {
                path: 'projects',
                lazy: async () => ({ Component: (await import('./workspaces/projects/ProjectsList')).ProjectsList }),
              },
              {
                path: 'deployments',
                lazy: async () => ({ Component: (await import('./workspaces/deployments/DeploymentsList')).DeploymentsList }),
              },
              {
                path: 'snapshots',
                lazy: async () => ({ Component: (await import('./workspaces/deployments/SnapshotsList')).SnapshotsList }),
              },
              {
                path: '',
                element: <Navigate to="projects" replace />,
              },
            ],
          },
        ],
      },
      {
        path: 'workspaces',
        element: <Workspaces />,
      },
      {
        path: 'extensions',
        Component: Outlet,
        children: [
          {
            path: 'develop/extensions/:extensionId',
            lazy: async () => ({ Component: (await import('./extensions/develop/ManageExtensions')).ManageExtensions }),
          },
          {
            path: 'develop',
            lazy: async () => ({ Component: (await import('./extensions/develop/Develop')).default }),
            children: [
              {
                path: 'extensions',
                lazy: async () => ({ Component: (await import('./extensions/develop/ExtensionsTab')).ExtensionsTab }),
              },
              {
                path: 'credentials',
                lazy: async () => ({ Component: (await import('./extensions/develop/CredentialsTab')).CredentialsTab }),
              },
              {
                path: '',
                element: <Navigate to="extensions" replace />,
              },
            ],
          },
          {
            path: 'installed/:extensionId',
            lazy: async () => ({ Component: (await import('./extensions/installed/ExtensionDetails')).default }),
          },
          {
            path: 'installed',
            lazy: async () => ({ Component: (await import('./extensions/installed/Installed')).default }),
          },
          {
            path: '',
            element: <Navigate to="develop" replace />,
          },
        ],
      },
    ],
  },
];
