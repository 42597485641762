// source: portal/v1/workspace_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var portal_v1_extension_service_pb = require('../../portal/v1/extension_service_pb.js');
goog.object.extend(proto, portal_v1_extension_service_pb);
var graph_v1_public_messages_pb = require('../../graph/v1/public_messages_pb.js');
goog.object.extend(proto, graph_v1_public_messages_pb);
goog.exportSymbol('proto.dibric.portal.v1.CopyItemRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CopyItemRequest.Options', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CopyItemResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateProjectRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateProjectResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateWorkspaceRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.CreateWorkspaceResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteItemRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteItemResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteWorkspaceRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.DeleteWorkspaceResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ExtensionTask', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetItemRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetItemRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetItemResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetWorkspaceRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.GetWorkspaceResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.InputTask', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListWorkspaceItemRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListWorkspaceItemRequest.Order', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListWorkspaceItemResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListWorkspaceRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListWorkspaceRequest.Order', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.ListWorkspaceResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.NavigationType', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Node', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Node.ErrorStatus', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Node.TaskCase', null, global);
goog.exportSymbol('proto.dibric.portal.v1.NodeHandle', null, global);
goog.exportSymbol('proto.dibric.portal.v1.NodeHandle.Type', null, global);
goog.exportSymbol('proto.dibric.portal.v1.UpdateProjectRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.UpdateProjectResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.UpdateWorkspaceRequest', null, global);
goog.exportSymbol('proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions', null, global);
goog.exportSymbol('proto.dibric.portal.v1.UpdateWorkspaceResponse', null, global);
goog.exportSymbol('proto.dibric.portal.v1.Workspace', null, global);
goog.exportSymbol('proto.dibric.portal.v1.WorkspaceItem', null, global);
goog.exportSymbol('proto.dibric.portal.v1.WorkspaceItemStatus', null, global);
goog.exportSymbol('proto.dibric.portal.v1.WorkspaceItemSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteItemRequest.displayName = 'proto.dibric.portal.v1.DeleteItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteItemResponse.displayName = 'proto.dibric.portal.v1.DeleteItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CopyItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CopyItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CopyItemRequest.displayName = 'proto.dibric.portal.v1.CopyItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CopyItemRequest.Options = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CopyItemRequest.Options, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CopyItemRequest.Options.displayName = 'proto.dibric.portal.v1.CopyItemRequest.Options';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CopyItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CopyItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CopyItemResponse.displayName = 'proto.dibric.portal.v1.CopyItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateProjectRequest.displayName = 'proto.dibric.portal.v1.CreateProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateProjectResponse.displayName = 'proto.dibric.portal.v1.CreateProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.UpdateProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.UpdateProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.UpdateProjectRequest.displayName = 'proto.dibric.portal.v1.UpdateProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.UpdateProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.UpdateProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.UpdateProjectResponse.displayName = 'proto.dibric.portal.v1.UpdateProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateWorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateWorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateWorkspaceRequest.displayName = 'proto.dibric.portal.v1.CreateWorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.CreateWorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.CreateWorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.CreateWorkspaceResponse.displayName = 'proto.dibric.portal.v1.CreateWorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetWorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetWorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetWorkspaceRequest.displayName = 'proto.dibric.portal.v1.GetWorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetWorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetWorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetWorkspaceResponse.displayName = 'proto.dibric.portal.v1.GetWorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteWorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteWorkspaceRequest.displayName = 'proto.dibric.portal.v1.DeleteWorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.DeleteWorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.DeleteWorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.DeleteWorkspaceResponse.displayName = 'proto.dibric.portal.v1.DeleteWorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.UpdateWorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.UpdateWorkspaceRequest.displayName = 'proto.dibric.portal.v1.UpdateWorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.UpdateWorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.UpdateWorkspaceResponse.displayName = 'proto.dibric.portal.v1.UpdateWorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListWorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListWorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListWorkspaceRequest.displayName = 'proto.dibric.portal.v1.ListWorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListWorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ListWorkspaceResponse.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ListWorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListWorkspaceResponse.displayName = 'proto.dibric.portal.v1.ListWorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListWorkspaceItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListWorkspaceItemRequest.displayName = 'proto.dibric.portal.v1.ListWorkspaceItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ListWorkspaceItemResponse.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ListWorkspaceItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ListWorkspaceItemResponse.displayName = 'proto.dibric.portal.v1.ListWorkspaceItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetItemRequest.displayName = 'proto.dibric.portal.v1.GetItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetItemRequest.ResponseOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetItemRequest.ResponseOptions.displayName = 'proto.dibric.portal.v1.GetItemRequest.ResponseOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.GetItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.GetItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.GetItemResponse.displayName = 'proto.dibric.portal.v1.GetItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Workspace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.Workspace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Workspace.displayName = 'proto.dibric.portal.v1.Workspace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.WorkspaceItemSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.WorkspaceItemSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.WorkspaceItemSummary.displayName = 'proto.dibric.portal.v1.WorkspaceItemSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.WorkspaceItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.WorkspaceItem.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.WorkspaceItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.WorkspaceItem.displayName = 'proto.dibric.portal.v1.WorkspaceItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.Node.repeatedFields_, proto.dibric.portal.v1.Node.oneofGroups_);
};
goog.inherits(proto.dibric.portal.v1.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.Node.displayName = 'proto.dibric.portal.v1.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.ExtensionTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.ExtensionTask.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.ExtensionTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.ExtensionTask.displayName = 'proto.dibric.portal.v1.ExtensionTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.InputTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.portal.v1.InputTask.repeatedFields_, null);
};
goog.inherits(proto.dibric.portal.v1.InputTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.InputTask.displayName = 'proto.dibric.portal.v1.InputTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.portal.v1.NodeHandle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.portal.v1.NodeHandle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.portal.v1.NodeHandle.displayName = 'proto.dibric.portal.v1.NodeHandle';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteItemRequest}
 */
proto.dibric.portal.v1.DeleteItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteItemRequest;
  return proto.dibric.portal.v1.DeleteItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteItemRequest}
 */
proto.dibric.portal.v1.DeleteItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.DeleteItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeleteItemRequest} returns this
 */
proto.dibric.portal.v1.DeleteItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.DeleteItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeleteItemRequest} returns this
 */
proto.dibric.portal.v1.DeleteItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteItemResponse}
 */
proto.dibric.portal.v1.DeleteItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteItemResponse;
  return proto.dibric.portal.v1.DeleteItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteItemResponse}
 */
proto.dibric.portal.v1.DeleteItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CopyItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CopyItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CopyItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    toWorkspace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.CopyItemRequest.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CopyItemRequest}
 */
proto.dibric.portal.v1.CopyItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CopyItemRequest;
  return proto.dibric.portal.v1.CopyItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CopyItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CopyItemRequest}
 */
proto.dibric.portal.v1.CopyItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToWorkspace(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.dibric.portal.v1.CopyItemRequest.Options;
      reader.readMessage(value,proto.dibric.portal.v1.CopyItemRequest.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CopyItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CopyItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CopyItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToWorkspace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.portal.v1.CopyItemRequest.Options.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CopyItemRequest.Options.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CopyItemRequest.Options.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CopyItemRequest.Options} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CopyItemRequest.Options.toObject = function(includeInstance, msg) {
  var f, obj = {
    copyAllDepedencies: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CopyItemRequest.Options}
 */
proto.dibric.portal.v1.CopyItemRequest.Options.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CopyItemRequest.Options;
  return proto.dibric.portal.v1.CopyItemRequest.Options.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CopyItemRequest.Options} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CopyItemRequest.Options}
 */
proto.dibric.portal.v1.CopyItemRequest.Options.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCopyAllDepedencies(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CopyItemRequest.Options.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CopyItemRequest.Options.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CopyItemRequest.Options} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CopyItemRequest.Options.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCopyAllDepedencies();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool copy_all_depedencies = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.CopyItemRequest.Options.prototype.getCopyAllDepedencies = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.CopyItemRequest.Options} returns this
 */
proto.dibric.portal.v1.CopyItemRequest.Options.prototype.setCopyAllDepedencies = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_item_response = 2;
 * @return {boolean}
 */
proto.dibric.portal.v1.CopyItemRequest.Options.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.CopyItemRequest.Options} returns this
 */
proto.dibric.portal.v1.CopyItemRequest.Options.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CopyItemRequest} returns this
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CopyItemRequest} returns this
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string to_workspace = 3;
 * @return {string}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.getToWorkspace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CopyItemRequest} returns this
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.setToWorkspace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CopyItemRequest} returns this
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CopyItemRequest} returns this
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Options options = 6;
 * @return {?proto.dibric.portal.v1.CopyItemRequest.Options}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.CopyItemRequest.Options} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.CopyItemRequest.Options, 6));
};


/**
 * @param {?proto.dibric.portal.v1.CopyItemRequest.Options|undefined} value
 * @return {!proto.dibric.portal.v1.CopyItemRequest} returns this
*/
proto.dibric.portal.v1.CopyItemRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CopyItemRequest} returns this
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CopyItemRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CopyItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CopyItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CopyItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CopyItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.WorkspaceItemSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CopyItemResponse}
 */
proto.dibric.portal.v1.CopyItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CopyItemResponse;
  return proto.dibric.portal.v1.CopyItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CopyItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CopyItemResponse}
 */
proto.dibric.portal.v1.CopyItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.WorkspaceItemSummary;
      reader.readMessage(value,proto.dibric.portal.v1.WorkspaceItemSummary.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CopyItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CopyItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CopyItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CopyItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.WorkspaceItemSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional WorkspaceItemSummary item = 1;
 * @return {?proto.dibric.portal.v1.WorkspaceItemSummary}
 */
proto.dibric.portal.v1.CopyItemResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.WorkspaceItemSummary} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.WorkspaceItemSummary, 1));
};


/**
 * @param {?proto.dibric.portal.v1.WorkspaceItemSummary|undefined} value
 * @return {!proto.dibric.portal.v1.CopyItemResponse} returns this
*/
proto.dibric.portal.v1.CopyItemResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CopyItemResponse} returns this
 */
proto.dibric.portal.v1.CopyItemResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CopyItemResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateProjectRequest}
 */
proto.dibric.portal.v1.CreateProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateProjectRequest;
  return proto.dibric.portal.v1.CreateProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateProjectRequest}
 */
proto.dibric.portal.v1.CreateProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions;
  return proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateProjectRequest} returns this
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateProjectRequest} returns this
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateProjectRequest} returns this
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ResponseOptions options = 4;
 * @return {?proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions, 4));
};


/**
 * @param {?proto.dibric.portal.v1.CreateProjectRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.CreateProjectRequest} returns this
*/
proto.dibric.portal.v1.CreateProjectRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateProjectRequest} returns this
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateProjectRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.WorkspaceItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateProjectResponse}
 */
proto.dibric.portal.v1.CreateProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateProjectResponse;
  return proto.dibric.portal.v1.CreateProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateProjectResponse}
 */
proto.dibric.portal.v1.CreateProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.WorkspaceItem;
      reader.readMessage(value,proto.dibric.portal.v1.WorkspaceItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.WorkspaceItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional WorkspaceItem item = 1;
 * @return {?proto.dibric.portal.v1.WorkspaceItem}
 */
proto.dibric.portal.v1.CreateProjectResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.WorkspaceItem} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.WorkspaceItem, 1));
};


/**
 * @param {?proto.dibric.portal.v1.WorkspaceItem|undefined} value
 * @return {!proto.dibric.portal.v1.CreateProjectResponse} returns this
*/
proto.dibric.portal.v1.CreateProjectResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateProjectResponse} returns this
 */
proto.dibric.portal.v1.CreateProjectResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateProjectResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.UpdateProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.UpdateProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    etag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest}
 */
proto.dibric.portal.v1.UpdateProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.UpdateProjectRequest;
  return proto.dibric.portal.v1.UpdateProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.UpdateProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest}
 */
proto.dibric.portal.v1.UpdateProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.UpdateProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.UpdateProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions}
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions;
  return proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions}
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest} returns this
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest} returns this
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string etag = 3;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest} returns this
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest} returns this
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest} returns this
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ResponseOptions options = 6;
 * @return {?proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions, 6));
};


/**
 * @param {?proto.dibric.portal.v1.UpdateProjectRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest} returns this
*/
proto.dibric.portal.v1.UpdateProjectRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.UpdateProjectRequest} returns this
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.UpdateProjectRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.UpdateProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.UpdateProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.UpdateProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.WorkspaceItemSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.UpdateProjectResponse}
 */
proto.dibric.portal.v1.UpdateProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.UpdateProjectResponse;
  return proto.dibric.portal.v1.UpdateProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.UpdateProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.UpdateProjectResponse}
 */
proto.dibric.portal.v1.UpdateProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.WorkspaceItemSummary;
      reader.readMessage(value,proto.dibric.portal.v1.WorkspaceItemSummary.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.UpdateProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.UpdateProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.UpdateProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.WorkspaceItemSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional WorkspaceItemSummary item = 1;
 * @return {?proto.dibric.portal.v1.WorkspaceItemSummary}
 */
proto.dibric.portal.v1.UpdateProjectResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.WorkspaceItemSummary} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.WorkspaceItemSummary, 1));
};


/**
 * @param {?proto.dibric.portal.v1.WorkspaceItemSummary|undefined} value
 * @return {!proto.dibric.portal.v1.UpdateProjectResponse} returns this
*/
proto.dibric.portal.v1.UpdateProjectResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.UpdateProjectResponse} returns this
 */
proto.dibric.portal.v1.UpdateProjectResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.UpdateProjectResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateWorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    image: jspb.Message.getFieldWithDefault(msg, 3, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateWorkspaceRequest;
  return proto.dibric.portal.v1.CreateWorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = new proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateWorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions;
  return proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image = 3;
 * @return {string}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ResponseOptions options = 5;
 * @return {?proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions, 5));
};


/**
 * @param {?proto.dibric.portal.v1.CreateWorkspaceRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest} returns this
*/
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateWorkspaceRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.CreateWorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.CreateWorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.Workspace.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.CreateWorkspaceResponse}
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.CreateWorkspaceResponse;
  return proto.dibric.portal.v1.CreateWorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.CreateWorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.CreateWorkspaceResponse}
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.Workspace;
      reader.readMessage(value,proto.dibric.portal.v1.Workspace.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.CreateWorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.CreateWorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.Workspace.serializeBinaryToWriter
    );
  }
};


/**
 * optional Workspace item = 1;
 * @return {?proto.dibric.portal.v1.Workspace}
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.Workspace} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Workspace, 1));
};


/**
 * @param {?proto.dibric.portal.v1.Workspace|undefined} value
 * @return {!proto.dibric.portal.v1.CreateWorkspaceResponse} returns this
*/
proto.dibric.portal.v1.CreateWorkspaceResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.CreateWorkspaceResponse} returns this
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.CreateWorkspaceResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetWorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetWorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetWorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetWorkspaceRequest}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetWorkspaceRequest;
  return proto.dibric.portal.v1.GetWorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetWorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetWorkspaceRequest}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetWorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetWorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetWorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions;
  return proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.GetWorkspaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResponseOptions options = 2;
 * @return {?proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions, 2));
};


/**
 * @param {?proto.dibric.portal.v1.GetWorkspaceRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.GetWorkspaceRequest} returns this
*/
proto.dibric.portal.v1.GetWorkspaceRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.GetWorkspaceRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetWorkspaceRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetWorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetWorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetWorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetWorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.Workspace.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetWorkspaceResponse}
 */
proto.dibric.portal.v1.GetWorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetWorkspaceResponse;
  return proto.dibric.portal.v1.GetWorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetWorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetWorkspaceResponse}
 */
proto.dibric.portal.v1.GetWorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.Workspace;
      reader.readMessage(value,proto.dibric.portal.v1.Workspace.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetWorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetWorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetWorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetWorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.Workspace.serializeBinaryToWriter
    );
  }
};


/**
 * optional Workspace item = 1;
 * @return {?proto.dibric.portal.v1.Workspace}
 */
proto.dibric.portal.v1.GetWorkspaceResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.Workspace} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Workspace, 1));
};


/**
 * @param {?proto.dibric.portal.v1.Workspace|undefined} value
 * @return {!proto.dibric.portal.v1.GetWorkspaceResponse} returns this
*/
proto.dibric.portal.v1.GetWorkspaceResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetWorkspaceResponse} returns this
 */
proto.dibric.portal.v1.GetWorkspaceResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetWorkspaceResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteWorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteWorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteWorkspaceRequest}
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteWorkspaceRequest;
  return proto.dibric.portal.v1.DeleteWorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteWorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteWorkspaceRequest}
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteWorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteWorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.DeleteWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.DeleteWorkspaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.DeleteWorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.DeleteWorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.DeleteWorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteWorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.DeleteWorkspaceResponse}
 */
proto.dibric.portal.v1.DeleteWorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.DeleteWorkspaceResponse;
  return proto.dibric.portal.v1.DeleteWorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.DeleteWorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.DeleteWorkspaceResponse}
 */
proto.dibric.portal.v1.DeleteWorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.DeleteWorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.DeleteWorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.DeleteWorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.DeleteWorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.UpdateWorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    etag: jspb.Message.getFieldWithDefault(msg, 5, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.UpdateWorkspaceRequest;
  return proto.dibric.portal.v1.UpdateWorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    case 6:
      var value = new proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.UpdateWorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions;
  return proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image = 4;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string etag = 5;
 * @return {string}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ResponseOptions options = 6;
 * @return {?proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions, 6));
};


/**
 * @param {?proto.dibric.portal.v1.UpdateWorkspaceRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest} returns this
*/
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.UpdateWorkspaceRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.UpdateWorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.Workspace.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceResponse}
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.UpdateWorkspaceResponse;
  return proto.dibric.portal.v1.UpdateWorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceResponse}
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.Workspace;
      reader.readMessage(value,proto.dibric.portal.v1.Workspace.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.UpdateWorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.UpdateWorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.Workspace.serializeBinaryToWriter
    );
  }
};


/**
 * optional Workspace item = 1;
 * @return {?proto.dibric.portal.v1.Workspace}
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.Workspace} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.Workspace, 1));
};


/**
 * @param {?proto.dibric.portal.v1.Workspace|undefined} value
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceResponse} returns this
*/
proto.dibric.portal.v1.UpdateWorkspaceResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.UpdateWorkspaceResponse} returns this
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.UpdateWorkspaceResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListWorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderByDescending: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    query: jspb.Message.getFieldWithDefault(msg, 5, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListWorkspaceRequest;
  return proto.dibric.portal.v1.ListWorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.dibric.portal.v1.ListWorkspaceRequest.Order} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrderByDescending(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 6:
      var value = new proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListWorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOrderByDescending();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.Order = {
  NAME: 0,
  LAST_CHANGED: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemsResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeImage: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions;
  return proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemsResponse(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemsResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeImage();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool include_items_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.prototype.getIncludeItemsResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.prototype.setIncludeItemsResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_image = 2;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.prototype.getIncludeImage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions.prototype.setIncludeImage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Order order_by = 3;
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest.Order}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.dibric.portal.v1.ListWorkspaceRequest.Order} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ListWorkspaceRequest.Order} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool order_by_descending = 4;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.getOrderByDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.setOrderByDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string query = 5;
 * @return {string}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ResponseOptions options = 6;
 * @return {?proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions, 6));
};


/**
 * @param {?proto.dibric.portal.v1.ListWorkspaceRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest} returns this
*/
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ListWorkspaceRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ListWorkspaceRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ListWorkspaceResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListWorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListWorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.dibric.portal.v1.Workspace.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListWorkspaceResponse}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListWorkspaceResponse;
  return proto.dibric.portal.v1.ListWorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListWorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListWorkspaceResponse}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 4:
      var value = new proto.dibric.portal.v1.Workspace;
      reader.readMessage(value,proto.dibric.portal.v1.Workspace.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListWorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListWorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dibric.portal.v1.Workspace.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceResponse} returns this
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceResponse} returns this
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_count = 3;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceResponse} returns this
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Workspace items = 4;
 * @return {!Array<!proto.dibric.portal.v1.Workspace>}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.Workspace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.Workspace, 4));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.Workspace>} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceResponse} returns this
*/
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dibric.portal.v1.Workspace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.Workspace}
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dibric.portal.v1.Workspace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ListWorkspaceResponse} returns this
 */
proto.dibric.portal.v1.ListWorkspaceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListWorkspaceItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderByDescending: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    query: jspb.Message.getFieldWithDefault(msg, 6, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListWorkspaceItemRequest;
  return proto.dibric.portal.v1.ListWorkspaceItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 4:
      var value = /** @type {!proto.dibric.portal.v1.ListWorkspaceItemRequest.Order} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrderByDescending(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 7:
      var value = new proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListWorkspaceItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOrderByDescending();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.Order = {
  NAME: 0,
  STATUS: 1,
  LAST_CHANGED: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions;
  return proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_number = 3;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Order order_by = 4;
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest.Order}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.dibric.portal.v1.ListWorkspaceItemRequest.Order} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemRequest.Order} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool order_by_descending = 5;
 * @return {boolean}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.getOrderByDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.setOrderByDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string query = 6;
 * @return {string}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ResponseOptions options = 7;
 * @return {?proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions, 7));
};


/**
 * @param {?proto.dibric.portal.v1.ListWorkspaceItemRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest} returns this
*/
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemRequest} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.ListWorkspaceItemRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ListWorkspaceItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalMatches: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.dibric.portal.v1.WorkspaceItemSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemResponse}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ListWorkspaceItemResponse;
  return proto.dibric.portal.v1.ListWorkspaceItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemResponse}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMatches(value);
      break;
    case 4:
      var value = new proto.dibric.portal.v1.WorkspaceItemSummary;
      reader.readMessage(value,proto.dibric.portal.v1.WorkspaceItemSummary.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ListWorkspaceItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ListWorkspaceItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalMatches();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dibric.portal.v1.WorkspaceItemSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemResponse} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemResponse} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_matches = 3;
 * @return {number}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.getTotalMatches = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemResponse} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.setTotalMatches = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated WorkspaceItemSummary items = 4;
 * @return {!Array<!proto.dibric.portal.v1.WorkspaceItemSummary>}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.WorkspaceItemSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.WorkspaceItemSummary, 4));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.WorkspaceItemSummary>} value
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemResponse} returns this
*/
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dibric.portal.v1.WorkspaceItemSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary}
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dibric.portal.v1.WorkspaceItemSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ListWorkspaceItemResponse} returns this
 */
proto.dibric.portal.v1.ListWorkspaceItemResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    options: (f = msg.getOptions()) && proto.dibric.portal.v1.GetItemRequest.ResponseOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetItemRequest}
 */
proto.dibric.portal.v1.GetItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetItemRequest;
  return proto.dibric.portal.v1.GetItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetItemRequest}
 */
proto.dibric.portal.v1.GetItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = new proto.dibric.portal.v1.GetItemRequest.ResponseOptions;
      reader.readMessage(value,proto.dibric.portal.v1.GetItemRequest.ResponseOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dibric.portal.v1.GetItemRequest.ResponseOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetItemRequest.ResponseOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetItemRequest.ResponseOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeItemResponse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetItemRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetItemRequest.ResponseOptions;
  return proto.dibric.portal.v1.GetItemRequest.ResponseOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetItemRequest.ResponseOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetItemRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeItemResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetItemRequest.ResponseOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetItemRequest.ResponseOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeItemResponse();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_item_response = 1;
 * @return {boolean}
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions.prototype.getIncludeItemResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.GetItemRequest.ResponseOptions} returns this
 */
proto.dibric.portal.v1.GetItemRequest.ResponseOptions.prototype.setIncludeItemResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.GetItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetItemRequest} returns this
 */
proto.dibric.portal.v1.GetItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.dibric.portal.v1.GetItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.GetItemRequest} returns this
 */
proto.dibric.portal.v1.GetItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ResponseOptions options = 3;
 * @return {?proto.dibric.portal.v1.GetItemRequest.ResponseOptions}
 */
proto.dibric.portal.v1.GetItemRequest.prototype.getOptions = function() {
  return /** @type{?proto.dibric.portal.v1.GetItemRequest.ResponseOptions} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.GetItemRequest.ResponseOptions, 3));
};


/**
 * @param {?proto.dibric.portal.v1.GetItemRequest.ResponseOptions|undefined} value
 * @return {!proto.dibric.portal.v1.GetItemRequest} returns this
*/
proto.dibric.portal.v1.GetItemRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetItemRequest} returns this
 */
proto.dibric.portal.v1.GetItemRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetItemRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.GetItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.GetItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.GetItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.dibric.portal.v1.WorkspaceItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.GetItemResponse}
 */
proto.dibric.portal.v1.GetItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.GetItemResponse;
  return proto.dibric.portal.v1.GetItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.GetItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.GetItemResponse}
 */
proto.dibric.portal.v1.GetItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.WorkspaceItem;
      reader.readMessage(value,proto.dibric.portal.v1.WorkspaceItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.GetItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.GetItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.GetItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.GetItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.portal.v1.WorkspaceItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional WorkspaceItem item = 1;
 * @return {?proto.dibric.portal.v1.WorkspaceItem}
 */
proto.dibric.portal.v1.GetItemResponse.prototype.getItem = function() {
  return /** @type{?proto.dibric.portal.v1.WorkspaceItem} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.WorkspaceItem, 1));
};


/**
 * @param {?proto.dibric.portal.v1.WorkspaceItem|undefined} value
 * @return {!proto.dibric.portal.v1.GetItemResponse} returns this
*/
proto.dibric.portal.v1.GetItemResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.GetItemResponse} returns this
 */
proto.dibric.portal.v1.GetItemResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.GetItemResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Workspace.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Workspace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Workspace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Workspace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    created: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastChanged: jspb.Message.getFieldWithDefault(msg, 6, 0),
    etag: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Workspace}
 */
proto.dibric.portal.v1.Workspace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Workspace;
  return proto.dibric.portal.v1.Workspace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Workspace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Workspace}
 */
proto.dibric.portal.v1.Workspace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastChanged(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Workspace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Workspace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Workspace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Workspace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLastChanged();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.Workspace.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Workspace} returns this
 */
proto.dibric.portal.v1.Workspace.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dibric.portal.v1.Workspace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Workspace} returns this
 */
proto.dibric.portal.v1.Workspace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.Workspace.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Workspace} returns this
 */
proto.dibric.portal.v1.Workspace.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image = 4;
 * @return {string}
 */
proto.dibric.portal.v1.Workspace.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Workspace} returns this
 */
proto.dibric.portal.v1.Workspace.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created = 5;
 * @return {number}
 */
proto.dibric.portal.v1.Workspace.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.Workspace} returns this
 */
proto.dibric.portal.v1.Workspace.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 last_changed = 6;
 * @return {number}
 */
proto.dibric.portal.v1.Workspace.prototype.getLastChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.Workspace} returns this
 */
proto.dibric.portal.v1.Workspace.prototype.setLastChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string etag = 7;
 * @return {string}
 */
proto.dibric.portal.v1.Workspace.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Workspace} returns this
 */
proto.dibric.portal.v1.Workspace.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.WorkspaceItemSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.WorkspaceItemSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.WorkspaceItemSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    etag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    created: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastChanged: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.WorkspaceItemSummary;
  return proto.dibric.portal.v1.WorkspaceItemSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.WorkspaceItemSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastChanged(value);
      break;
    case 7:
      var value = /** @type {!proto.dibric.portal.v1.WorkspaceItemStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.WorkspaceItemSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.WorkspaceItemSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.WorkspaceItemSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLastChanged();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary} returns this
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary} returns this
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary} returns this
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string etag = 4;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary} returns this
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created = 5;
 * @return {number}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary} returns this
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 last_changed = 6;
 * @return {number}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.getLastChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary} returns this
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.setLastChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional WorkspaceItemStatus status = 7;
 * @return {!proto.dibric.portal.v1.WorkspaceItemStatus}
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.getStatus = function() {
  return /** @type {!proto.dibric.portal.v1.WorkspaceItemStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.dibric.portal.v1.WorkspaceItemStatus} value
 * @return {!proto.dibric.portal.v1.WorkspaceItemSummary} returns this
 */
proto.dibric.portal.v1.WorkspaceItemSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.WorkspaceItem.repeatedFields_ = [11,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.WorkspaceItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.WorkspaceItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.WorkspaceItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    etag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    created: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastChanged: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.dibric.portal.v1.Node.toObject, includeInstance),
    rootNodeId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    edgesList: jspb.Message.toObjectList(msg.getEdgesList(),
    graph_v1_public_messages_pb.Edge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.WorkspaceItem}
 */
proto.dibric.portal.v1.WorkspaceItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.WorkspaceItem;
  return proto.dibric.portal.v1.WorkspaceItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.WorkspaceItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.WorkspaceItem}
 */
proto.dibric.portal.v1.WorkspaceItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastChanged(value);
      break;
    case 9:
      var value = /** @type {!proto.dibric.portal.v1.WorkspaceItemStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = new proto.dibric.portal.v1.Node;
      reader.readMessage(value,proto.dibric.portal.v1.Node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRootNodeId(value);
      break;
    case 13:
      var value = new graph_v1_public_messages_pb.Edge;
      reader.readMessage(value,graph_v1_public_messages_pb.Edge.deserializeBinaryFromReader);
      msg.addEdges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.WorkspaceItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.WorkspaceItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.WorkspaceItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLastChanged();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.dibric.portal.v1.Node.serializeBinaryToWriter
    );
  }
  f = message.getRootNodeId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      graph_v1_public_messages_pb.Edge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string etag = 4;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created = 5;
 * @return {number}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 last_changed = 6;
 * @return {number}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getLastChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.setLastChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional WorkspaceItemStatus status = 9;
 * @return {!proto.dibric.portal.v1.WorkspaceItemStatus}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getStatus = function() {
  return /** @type {!proto.dibric.portal.v1.WorkspaceItemStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.dibric.portal.v1.WorkspaceItemStatus} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated Node nodes = 11;
 * @return {!Array<!proto.dibric.portal.v1.Node>}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.Node, 11));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.Node>} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
*/
proto.dibric.portal.v1.WorkspaceItem.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.dibric.portal.v1.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.Node}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.dibric.portal.v1.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * optional string root_node_id = 12;
 * @return {string}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getRootNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.setRootNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated dibric.graph.v1.Edge edges = 13;
 * @return {!Array<!proto.dibric.graph.v1.Edge>}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.getEdgesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.Edge>} */ (
    jspb.Message.getRepeatedWrapperField(this, graph_v1_public_messages_pb.Edge, 13));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.Edge>} value
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
*/
proto.dibric.portal.v1.WorkspaceItem.prototype.setEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.dibric.graph.v1.Edge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.Edge}
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.addEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.dibric.graph.v1.Edge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.WorkspaceItem} returns this
 */
proto.dibric.portal.v1.WorkspaceItem.prototype.clearEdgesList = function() {
  return this.setEdgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.Node.repeatedFields_ = [8];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dibric.portal.v1.Node.oneofGroups_ = [[11,12]];

/**
 * @enum {number}
 */
proto.dibric.portal.v1.Node.TaskCase = {
  TASK_NOT_SET: 0,
  EXTENSION_TASK: 11,
  INPUT: 12
};

/**
 * @return {proto.dibric.portal.v1.Node.TaskCase}
 */
proto.dibric.portal.v1.Node.prototype.getTaskCase = function() {
  return /** @type {proto.dibric.portal.v1.Node.TaskCase} */(jspb.Message.computeOneofCase(this, proto.dibric.portal.v1.Node.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    position: (f = msg.getPosition()) && graph_v1_public_messages_pb.NodePosition.toObject(includeInstance, f),
    sources: jspb.Message.getFieldWithDefault(msg, 6, 0),
    targets: jspb.Message.getFieldWithDefault(msg, 7, 0),
    errorStatusList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    partitionId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    extensionTask: (f = msg.getExtensionTask()) && proto.dibric.portal.v1.ExtensionTask.toObject(includeInstance, f),
    input: (f = msg.getInput()) && proto.dibric.portal.v1.InputTask.toObject(includeInstance, f),
    isConfigurable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.Node}
 */
proto.dibric.portal.v1.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.Node;
  return proto.dibric.portal.v1.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.Node}
 */
proto.dibric.portal.v1.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 5:
      var value = new graph_v1_public_messages_pb.NodePosition;
      reader.readMessage(value,graph_v1_public_messages_pb.NodePosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 6:
      var value = /** @type {!proto.dibric.graph.v1.NodeHandlePosition} */ (reader.readEnum());
      msg.setSources(value);
      break;
    case 7:
      var value = /** @type {!proto.dibric.graph.v1.NodeHandlePosition} */ (reader.readEnum());
      msg.setTargets(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.dibric.portal.v1.Node.ErrorStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addErrorStatus(values[i]);
      }
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartitionId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 11:
      var value = new proto.dibric.portal.v1.ExtensionTask;
      reader.readMessage(value,proto.dibric.portal.v1.ExtensionTask.deserializeBinaryFromReader);
      msg.setExtensionTask(value);
      break;
    case 12:
      var value = new proto.dibric.portal.v1.InputTask;
      reader.readMessage(value,proto.dibric.portal.v1.InputTask.deserializeBinaryFromReader);
      msg.setInput(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConfigurable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      graph_v1_public_messages_pb.NodePosition.serializeBinaryToWriter
    );
  }
  f = message.getSources();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTargets();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getErrorStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getPartitionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExtensionTask();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.dibric.portal.v1.ExtensionTask.serializeBinaryToWriter
    );
  }
  f = message.getInput();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.dibric.portal.v1.InputTask.serializeBinaryToWriter
    );
  }
  f = message.getIsConfigurable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.Node.ErrorStatus = {
  UNKNOWN: 0,
  INCOMPATIBLE_VARIABLE_CONNECTION: 1,
  EXTENSION_DEPRECATED: 2,
  GRAPH_CONFIG_NOT_FOUND: 3,
  TASK_CONFIG_NOT_FOUND: 4,
  EXTENSION_DELETED: 5,
  VARIABLE_CONNECTION_NOTFOUND: 6,
  NOT_CONFIGURED: 7
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.Node.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dibric.portal.v1.Node.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.Node.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo = 4;
 * @return {string}
 */
proto.dibric.portal.v1.Node.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional dibric.graph.v1.NodePosition position = 5;
 * @return {?proto.dibric.graph.v1.NodePosition}
 */
proto.dibric.portal.v1.Node.prototype.getPosition = function() {
  return /** @type{?proto.dibric.graph.v1.NodePosition} */ (
    jspb.Message.getWrapperField(this, graph_v1_public_messages_pb.NodePosition, 5));
};


/**
 * @param {?proto.dibric.graph.v1.NodePosition|undefined} value
 * @return {!proto.dibric.portal.v1.Node} returns this
*/
proto.dibric.portal.v1.Node.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.Node.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional dibric.graph.v1.NodeHandlePosition sources = 6;
 * @return {!proto.dibric.graph.v1.NodeHandlePosition}
 */
proto.dibric.portal.v1.Node.prototype.getSources = function() {
  return /** @type {!proto.dibric.graph.v1.NodeHandlePosition} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.dibric.graph.v1.NodeHandlePosition} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setSources = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional dibric.graph.v1.NodeHandlePosition targets = 7;
 * @return {!proto.dibric.graph.v1.NodeHandlePosition}
 */
proto.dibric.portal.v1.Node.prototype.getTargets = function() {
  return /** @type {!proto.dibric.graph.v1.NodeHandlePosition} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.dibric.graph.v1.NodeHandlePosition} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setTargets = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated ErrorStatus error_status = 8;
 * @return {!Array<!proto.dibric.portal.v1.Node.ErrorStatus>}
 */
proto.dibric.portal.v1.Node.prototype.getErrorStatusList = function() {
  return /** @type {!Array<!proto.dibric.portal.v1.Node.ErrorStatus>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.Node.ErrorStatus>} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setErrorStatusList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.dibric.portal.v1.Node.ErrorStatus} value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.addErrorStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.clearErrorStatusList = function() {
  return this.setErrorStatusList([]);
};


/**
 * optional string partition_id = 9;
 * @return {string}
 */
proto.dibric.portal.v1.Node.prototype.getPartitionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setPartitionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string client_id = 10;
 * @return {string}
 */
proto.dibric.portal.v1.Node.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional ExtensionTask extension_task = 11;
 * @return {?proto.dibric.portal.v1.ExtensionTask}
 */
proto.dibric.portal.v1.Node.prototype.getExtensionTask = function() {
  return /** @type{?proto.dibric.portal.v1.ExtensionTask} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.ExtensionTask, 11));
};


/**
 * @param {?proto.dibric.portal.v1.ExtensionTask|undefined} value
 * @return {!proto.dibric.portal.v1.Node} returns this
*/
proto.dibric.portal.v1.Node.prototype.setExtensionTask = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.dibric.portal.v1.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.clearExtensionTask = function() {
  return this.setExtensionTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.Node.prototype.hasExtensionTask = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional InputTask input = 12;
 * @return {?proto.dibric.portal.v1.InputTask}
 */
proto.dibric.portal.v1.Node.prototype.getInput = function() {
  return /** @type{?proto.dibric.portal.v1.InputTask} */ (
    jspb.Message.getWrapperField(this, proto.dibric.portal.v1.InputTask, 12));
};


/**
 * @param {?proto.dibric.portal.v1.InputTask|undefined} value
 * @return {!proto.dibric.portal.v1.Node} returns this
*/
proto.dibric.portal.v1.Node.prototype.setInput = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.dibric.portal.v1.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.clearInput = function() {
  return this.setInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.portal.v1.Node.prototype.hasInput = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool is_configurable = 13;
 * @return {boolean}
 */
proto.dibric.portal.v1.Node.prototype.getIsConfigurable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.portal.v1.Node} returns this
 */
proto.dibric.portal.v1.Node.prototype.setIsConfigurable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.ExtensionTask.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.ExtensionTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.ExtensionTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ExtensionTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inputHandlesList: jspb.Message.toObjectList(msg.getInputHandlesList(),
    proto.dibric.portal.v1.NodeHandle.toObject, includeInstance),
    outputHandlesList: jspb.Message.toObjectList(msg.getOutputHandlesList(),
    proto.dibric.portal.v1.NodeHandle.toObject, includeInstance),
    navigationRule: jspb.Message.getFieldWithDefault(msg, 4, 0),
    extensionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    extensionVersion: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.ExtensionTask}
 */
proto.dibric.portal.v1.ExtensionTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.ExtensionTask;
  return proto.dibric.portal.v1.ExtensionTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.ExtensionTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.ExtensionTask}
 */
proto.dibric.portal.v1.ExtensionTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dibric.portal.v1.ExtensionTaskType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.dibric.portal.v1.NodeHandle;
      reader.readMessage(value,proto.dibric.portal.v1.NodeHandle.deserializeBinaryFromReader);
      msg.addInputHandles(value);
      break;
    case 3:
      var value = new proto.dibric.portal.v1.NodeHandle;
      reader.readMessage(value,proto.dibric.portal.v1.NodeHandle.deserializeBinaryFromReader);
      msg.addOutputHandles(value);
      break;
    case 4:
      var value = /** @type {!proto.dibric.portal.v1.NavigationType} */ (reader.readEnum());
      msg.setNavigationRule(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtensionVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.ExtensionTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.ExtensionTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.ExtensionTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getInputHandlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dibric.portal.v1.NodeHandle.serializeBinaryToWriter
    );
  }
  f = message.getOutputHandlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dibric.portal.v1.NodeHandle.serializeBinaryToWriter
    );
  }
  f = message.getNavigationRule();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExtensionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExtensionVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional ExtensionTaskType type = 1;
 * @return {!proto.dibric.portal.v1.ExtensionTaskType}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.getType = function() {
  return /** @type {!proto.dibric.portal.v1.ExtensionTaskType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dibric.portal.v1.ExtensionTaskType} value
 * @return {!proto.dibric.portal.v1.ExtensionTask} returns this
 */
proto.dibric.portal.v1.ExtensionTask.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated NodeHandle input_handles = 2;
 * @return {!Array<!proto.dibric.portal.v1.NodeHandle>}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.getInputHandlesList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.NodeHandle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.NodeHandle, 2));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.NodeHandle>} value
 * @return {!proto.dibric.portal.v1.ExtensionTask} returns this
*/
proto.dibric.portal.v1.ExtensionTask.prototype.setInputHandlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dibric.portal.v1.NodeHandle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.NodeHandle}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.addInputHandles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dibric.portal.v1.NodeHandle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ExtensionTask} returns this
 */
proto.dibric.portal.v1.ExtensionTask.prototype.clearInputHandlesList = function() {
  return this.setInputHandlesList([]);
};


/**
 * repeated NodeHandle output_handles = 3;
 * @return {!Array<!proto.dibric.portal.v1.NodeHandle>}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.getOutputHandlesList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.NodeHandle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.NodeHandle, 3));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.NodeHandle>} value
 * @return {!proto.dibric.portal.v1.ExtensionTask} returns this
*/
proto.dibric.portal.v1.ExtensionTask.prototype.setOutputHandlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dibric.portal.v1.NodeHandle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.NodeHandle}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.addOutputHandles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dibric.portal.v1.NodeHandle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.ExtensionTask} returns this
 */
proto.dibric.portal.v1.ExtensionTask.prototype.clearOutputHandlesList = function() {
  return this.setOutputHandlesList([]);
};


/**
 * optional NavigationType navigation_rule = 4;
 * @return {!proto.dibric.portal.v1.NavigationType}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.getNavigationRule = function() {
  return /** @type {!proto.dibric.portal.v1.NavigationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.dibric.portal.v1.NavigationType} value
 * @return {!proto.dibric.portal.v1.ExtensionTask} returns this
 */
proto.dibric.portal.v1.ExtensionTask.prototype.setNavigationRule = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string extension_id = 5;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.getExtensionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionTask} returns this
 */
proto.dibric.portal.v1.ExtensionTask.prototype.setExtensionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string extension_version = 6;
 * @return {string}
 */
proto.dibric.portal.v1.ExtensionTask.prototype.getExtensionVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.ExtensionTask} returns this
 */
proto.dibric.portal.v1.ExtensionTask.prototype.setExtensionVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.portal.v1.InputTask.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.InputTask.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.InputTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.InputTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.InputTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    outputHandlesList: jspb.Message.toObjectList(msg.getOutputHandlesList(),
    proto.dibric.portal.v1.NodeHandle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.InputTask}
 */
proto.dibric.portal.v1.InputTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.InputTask;
  return proto.dibric.portal.v1.InputTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.InputTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.InputTask}
 */
proto.dibric.portal.v1.InputTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.portal.v1.NodeHandle;
      reader.readMessage(value,proto.dibric.portal.v1.NodeHandle.deserializeBinaryFromReader);
      msg.addOutputHandles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.InputTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.InputTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.InputTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.InputTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutputHandlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.portal.v1.NodeHandle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeHandle output_handles = 1;
 * @return {!Array<!proto.dibric.portal.v1.NodeHandle>}
 */
proto.dibric.portal.v1.InputTask.prototype.getOutputHandlesList = function() {
  return /** @type{!Array<!proto.dibric.portal.v1.NodeHandle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.portal.v1.NodeHandle, 1));
};


/**
 * @param {!Array<!proto.dibric.portal.v1.NodeHandle>} value
 * @return {!proto.dibric.portal.v1.InputTask} returns this
*/
proto.dibric.portal.v1.InputTask.prototype.setOutputHandlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.portal.v1.NodeHandle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.portal.v1.NodeHandle}
 */
proto.dibric.portal.v1.InputTask.prototype.addOutputHandles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.portal.v1.NodeHandle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.portal.v1.InputTask} returns this
 */
proto.dibric.portal.v1.InputTask.prototype.clearOutputHandlesList = function() {
  return this.setOutputHandlesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.portal.v1.NodeHandle.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.portal.v1.NodeHandle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.portal.v1.NodeHandle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.NodeHandle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.portal.v1.NodeHandle}
 */
proto.dibric.portal.v1.NodeHandle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.portal.v1.NodeHandle;
  return proto.dibric.portal.v1.NodeHandle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.portal.v1.NodeHandle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.portal.v1.NodeHandle}
 */
proto.dibric.portal.v1.NodeHandle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.dibric.portal.v1.NodeHandle.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.portal.v1.NodeHandle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.portal.v1.NodeHandle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.portal.v1.NodeHandle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.portal.v1.NodeHandle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.NodeHandle.Type = {
  UNKNOWN: 0,
  SUCCESS: 1,
  FAIL: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.portal.v1.NodeHandle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NodeHandle} returns this
 */
proto.dibric.portal.v1.NodeHandle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.dibric.portal.v1.NodeHandle.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NodeHandle} returns this
 */
proto.dibric.portal.v1.NodeHandle.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.portal.v1.NodeHandle.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.portal.v1.NodeHandle} returns this
 */
proto.dibric.portal.v1.NodeHandle.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Type type = 4;
 * @return {!proto.dibric.portal.v1.NodeHandle.Type}
 */
proto.dibric.portal.v1.NodeHandle.prototype.getType = function() {
  return /** @type {!proto.dibric.portal.v1.NodeHandle.Type} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.dibric.portal.v1.NodeHandle.Type} value
 * @return {!proto.dibric.portal.v1.NodeHandle} returns this
 */
proto.dibric.portal.v1.NodeHandle.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.dibric.portal.v1.WorkspaceItemStatus = {
  UNKNOWN_STATUS: 0,
  OK_STATUS: 1,
  WARNING_STATUS: 2,
  ERROR_STATUS: 3
};

/**
 * @enum {number}
 */
proto.dibric.portal.v1.NavigationType = {
  UNKNOWN_NAVIGATION: 0,
  BLOCK_NAVIGATION: 1,
  SKIP_NAVIGATION: 2,
  ACCEPT_NAVIGATION: 3
};

goog.object.extend(exports, proto.dibric.portal.v1);
