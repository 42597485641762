import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Fade,
  IconButton,
  LinearProgress,
  Modal,
  Pagination,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import noData from '../assets/no-data.png';
import { Breadcrumb } from '../breadcrumbs/Breadcrumb';
import {
  CreateWorkspaceRequest,
  ListWorkspaceRequest,
  Workspace,
  createWorkspace,
  listWorkspace
} from '../services/workspace';
import { SomethingWrongDialog } from '../shared/SomethingWrongDialog';
import { WorkspaceCard } from './WorkspaceCard';

export default function Workspaces() {
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const pageSize = 25;

  useEffect(
    () => {
      let subscribed = true;

      async function fetch() {
        setLoading(true);
        try {
          const listRequest = new ListWorkspaceRequest();
          listRequest.setPageSize(pageSize);
          listRequest.setPageNumber(page - 1);
          const result = await listWorkspace(listRequest);
          if (subscribed) {
            setWorkspaces(result.getItemsList());
            setTotalCount(result.getTotalCount());
            setLoading(false);
          }
        } catch {
          setLoading(false);
        }
      }

      fetch();

      return () => { subscribed = false; };
    },
    [page],
  );

  async function handleFileUpload(files: FileList | null) {
    if (!files || !files[0]) {
      return;
    }

    try {
      const file = files[0];
      const newImage = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!reader.result) {
            reject('There\'s no content');
            return;
          }
          resolve(reader.result.toString());
        };
        reader.onerror = error => reject(error);
      });

      setImage(newImage);

    } catch (error: unknown) {
      setError('Could not use that image. Please try again with another file');
      console.error(error);
    }
  }

  function handleNameChanged(newValue: string) {
    setNameError('');
    setName(newValue.substring(0, 40));
  }

  function handleDescriptionChanged(newValue: string) {
    setDescription(newValue.substring(0, 80));
  }

  function handleClose() {
    setOpen(false);
    setName('');
    setDescription('');
    setNameError('');
    setImage('');
  }

  function handlePageChange(newPage: number) {
    setPage(newPage);
  }

  async function handleCreate() {
    if (!name) {
      setNameError('Workspace name is required');
      return;
    }

    const options = new CreateWorkspaceRequest.ResponseOptions();
    options.setIncludeItemResponse(true);

    const request = new CreateWorkspaceRequest();
    request.setOptions(options);
    request.setDescription(description);
    request.setName(name);
    request.setImage(image);

    try {
      setLoading(true);
      const response = await createWorkspace(request);
      const ws = response.getItem();
      if (ws) {
        setWorkspaces([...workspaces, ws]);
      }
    } catch (error: unknown) {
      console.log(error);
      setError('Could not create the workspace. Please try again later');
      throw error;
    } finally {
      setLoading(false);
      setOpen(false);
      setName('');
      setDescription('');
      setNameError('');
      setImage('');
    }
  }

  const breadcrumbs = [
    <Breadcrumb to="/workspaces" key="1">workspaces</Breadcrumb>,
  ];
  return (
    <Stack flexDirection="column" alignItems="stretch" sx={{ height: '100%' }}>
      <LinearProgress
        color="primary"
        style={{
          height: 5,
          visibility: loading ? 'visible' : 'hidden',
        }}
        aria-label="Loading Workspaces"
      />
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb"
        sx={{ m: 2, mt: '15px' }}>
        {breadcrumbs}
      </Breadcrumbs>
      <Container sx={{ display: 'flex', flexDirection: 'column', mt: 2, flex: 1 }}>
        <Stack sx={{ my: 4, flex: 1 }}>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                aria-label="Loading Workspaces"
              />
            </Box>
          )}
          {workspaces.length === 0 && !loading && (
            <Stack spacing={2} flexDirection="column" alignItems="center" sx={{ flex: 1 }}>
              <img src={noData} alt="No data illustration" style={{ maxHeight: 400 }} height="400" />
              <Typography variant="h5">
                Create your first workspace
              </Typography>
              <Typography variant="body2">
                A workspace is a place where a user can manage and organize their projects. It can include project files, settings, deployment slots, and snapshots. Workspaces are designed to help you maintain an organized and efficient workflow by grouping related resources together.
              </Typography>
              <Tooltip
                title="Create a new workspace"
                placement="left"
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(true)}
                  startIcon={<AddIcon />}
                >
                  Create
                </Button>
              </Tooltip>
            </Stack>
          )}
          {workspaces.length !== 0 && (
            <>
              <Toolbar sx={{ display: 'flex' }}>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Workspaces
                </Typography>
                <Box>
                  <Tooltip
                    title="Create a new workspace"
                    placement="left"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setOpen(true)}
                      startIcon={<AddIcon />}
                    >
                      Create
                    </Button>
                  </Tooltip>
                </Box>
              </Toolbar>
              <Stack
                flexDirection="row"
                flexWrap="wrap"
                alignContent="flex-start"
                alignItems="flex-start"
                sx={{ flex: 1 }}
              >
                {workspaces.map((workspace) => (
                  <WorkspaceCard
                    key={workspace.getId()}
                    name={workspace.getName()}
                    description={workspace.getDescription()}
                    image={workspace.getImage()}
                    to={`/workspaces/${workspace.getId()}`}
                    sx={{
                      mr: 3,
                      my: 2,
                      ml: 0,
                    }}
                  />
                ))}
              </Stack>
            </>
          )}
        </Stack>
        <Divider sx={{ my: 4 }} />
        <Pagination
          sx={{ alignSelf: 'flex-end', mb: 2 }}
          count={Math.floor(totalCount / pageSize) + 1}
          page={page}
          onChange={(event, newPage) => handlePageChange(newPage)}
          color="primary"
        />
      </Container>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Paper
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              maxHeight: '100%',
              overflowY: 'auto',
            }}
            elevation={20}
          >
            <LinearProgress
              color="primary"
              style={{ width: '100%', height: 3, visibility: loading ? 'visible' : 'hidden' }}
              aria-label="Loading Workspaces"
            />
            <IconButton style={{ float: 'right' }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Stack direction="column" spacing={3} sx={{ margin: 4 }}>
              <WorkspaceCard
                name={name}
                description={description}
                image={image}
                sx={{ alignSelf: 'center' }}
              />
              <Typography id="modal-title" variant="h6" component="h2">
                Create new workspace
              </Typography>
              <TextField
                autoFocus={true}
                required
                disabled={loading}
                value={name}
                onChange={(event) => handleNameChanged(event.target.value)}
                id="extension-id"
                name="extension-id"
                label="Name"
                error={Boolean(nameError)}
                helperText={nameError ? nameError : 'The workspace name'}
                variant="standard"
              />
              <TextField
                label="Description"
                id="description"
                name="description"
                variant="standard"
                rows={3}
                value={description}
                onChange={(event) => handleDescriptionChanged(event.target.value)}
                helperText="The workspace short description"
                disabled={loading}
                multiline
              />
              <Stack direction="row" spacing={2} alignItems="center">
                {image && (
                  <Chip label="Logo" onDelete={() => setImage('')} />)}
                <Button
                  style={{ flex: 1 }}
                  disabled={loading}
                  size="large"
                  variant="contained"
                  component="label"
                  startIcon={<ImageIcon />}
                  color="primary">
                  Logo
                  <input
                    type="file"
                    onChange={event => handleFileUpload(event.target.files)}
                    value=""
                    accept="image/*"
                    hidden
                  />
                </Button>
              </Stack>
              <Stack direction="row" sx={{ mt: 4 }}>
                <Button disabled={loading} onClick={handleClose} color="inherit">
                  Cancel
                </Button>
                <div style={{ flex: 1 }} />
                <Button variant="contained" disabled={loading} onClick={handleCreate}>
                  {loading
                    ? <CircularProgress
                      color="primary"
                      size={30}
                      aria-label="Loading Workspaces"
                    />
                    : 'Create'
                  }
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </Modal>
      <SomethingWrongDialog open={Boolean(error)} onClose={() => setError('')} text={error} />
    </Stack>
  );
}
