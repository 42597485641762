// source: graph/v1/public_messages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.dibric.graph.v1.BinaryLimitation', null, global);
goog.exportSymbol('proto.dibric.graph.v1.BinaryType', null, global);
goog.exportSymbol('proto.dibric.graph.v1.BooleanType', null, global);
goog.exportSymbol('proto.dibric.graph.v1.DatetimeLimitation', null, global);
goog.exportSymbol('proto.dibric.graph.v1.DatetimeType', null, global);
goog.exportSymbol('proto.dibric.graph.v1.DecimalLimitation', null, global);
goog.exportSymbol('proto.dibric.graph.v1.DecimalLimitation.Border', null, global);
goog.exportSymbol('proto.dibric.graph.v1.DecimalLimitation.Interval', null, global);
goog.exportSymbol('proto.dibric.graph.v1.DecimalType', null, global);
goog.exportSymbol('proto.dibric.graph.v1.Edge', null, global);
goog.exportSymbol('proto.dibric.graph.v1.HandleType', null, global);
goog.exportSymbol('proto.dibric.graph.v1.InputVariable', null, global);
goog.exportSymbol('proto.dibric.graph.v1.InputVariables', null, global);
goog.exportSymbol('proto.dibric.graph.v1.IntegerLimitation', null, global);
goog.exportSymbol('proto.dibric.graph.v1.IntegerLimitation.Interval', null, global);
goog.exportSymbol('proto.dibric.graph.v1.IntegerType', null, global);
goog.exportSymbol('proto.dibric.graph.v1.KeyValue', null, global);
goog.exportSymbol('proto.dibric.graph.v1.NodeHandle', null, global);
goog.exportSymbol('proto.dibric.graph.v1.NodeHandle.VariablesCase', null, global);
goog.exportSymbol('proto.dibric.graph.v1.NodeHandlePosition', null, global);
goog.exportSymbol('proto.dibric.graph.v1.NodePosition', null, global);
goog.exportSymbol('proto.dibric.graph.v1.OutputVariables', null, global);
goog.exportSymbol('proto.dibric.graph.v1.StringComparison', null, global);
goog.exportSymbol('proto.dibric.graph.v1.StringComparison.Type', null, global);
goog.exportSymbol('proto.dibric.graph.v1.StringLimitation', null, global);
goog.exportSymbol('proto.dibric.graph.v1.StringLimitation.ValueRestriction', null, global);
goog.exportSymbol('proto.dibric.graph.v1.StringType', null, global);
goog.exportSymbol('proto.dibric.graph.v1.TypeDescription', null, global);
goog.exportSymbol('proto.dibric.graph.v1.TypeDescription.ExtendedTypeCase', null, global);
goog.exportSymbol('proto.dibric.graph.v1.TypeDescription.LimitationCase', null, global);
goog.exportSymbol('proto.dibric.graph.v1.Value', null, global);
goog.exportSymbol('proto.dibric.graph.v1.Value.BinaryValue', null, global);
goog.exportSymbol('proto.dibric.graph.v1.Value.ValueCase', null, global);
goog.exportSymbol('proto.dibric.graph.v1.ValueType', null, global);
goog.exportSymbol('proto.dibric.graph.v1.Variable', null, global);
goog.exportSymbol('proto.dibric.graph.v1.VariableKey', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.NodePosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.NodePosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.NodePosition.displayName = 'proto.dibric.graph.v1.NodePosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.Edge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.Edge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.Edge.displayName = 'proto.dibric.graph.v1.Edge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dibric.graph.v1.Value.oneofGroups_);
};
goog.inherits(proto.dibric.graph.v1.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.Value.displayName = 'proto.dibric.graph.v1.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.Value.BinaryValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.Value.BinaryValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.Value.BinaryValue.displayName = 'proto.dibric.graph.v1.Value.BinaryValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.InputVariables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.graph.v1.InputVariables.repeatedFields_, null);
};
goog.inherits(proto.dibric.graph.v1.InputVariables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.InputVariables.displayName = 'proto.dibric.graph.v1.InputVariables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.OutputVariables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.graph.v1.OutputVariables.repeatedFields_, null);
};
goog.inherits(proto.dibric.graph.v1.OutputVariables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.OutputVariables.displayName = 'proto.dibric.graph.v1.OutputVariables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.NodeHandle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dibric.graph.v1.NodeHandle.oneofGroups_);
};
goog.inherits(proto.dibric.graph.v1.NodeHandle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.NodeHandle.displayName = 'proto.dibric.graph.v1.NodeHandle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.VariableKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.VariableKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.VariableKey.displayName = 'proto.dibric.graph.v1.VariableKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.KeyValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.KeyValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.KeyValue.displayName = 'proto.dibric.graph.v1.KeyValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.InputVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.InputVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.InputVariable.displayName = 'proto.dibric.graph.v1.InputVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.Variable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.Variable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.Variable.displayName = 'proto.dibric.graph.v1.Variable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.TypeDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dibric.graph.v1.TypeDescription.oneofGroups_);
};
goog.inherits(proto.dibric.graph.v1.TypeDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.TypeDescription.displayName = 'proto.dibric.graph.v1.TypeDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.StringType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.StringType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.StringType.displayName = 'proto.dibric.graph.v1.StringType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.DecimalType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.DecimalType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.DecimalType.displayName = 'proto.dibric.graph.v1.DecimalType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.IntegerType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.IntegerType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.IntegerType.displayName = 'proto.dibric.graph.v1.IntegerType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.BooleanType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.BooleanType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.BooleanType.displayName = 'proto.dibric.graph.v1.BooleanType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.DatetimeType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.DatetimeType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.DatetimeType.displayName = 'proto.dibric.graph.v1.DatetimeType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.BinaryType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.BinaryType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.BinaryType.displayName = 'proto.dibric.graph.v1.BinaryType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.BinaryLimitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.BinaryLimitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.BinaryLimitation.displayName = 'proto.dibric.graph.v1.BinaryLimitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.StringLimitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.graph.v1.StringLimitation.repeatedFields_, null);
};
goog.inherits(proto.dibric.graph.v1.StringLimitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.StringLimitation.displayName = 'proto.dibric.graph.v1.StringLimitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.IntegerLimitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.graph.v1.IntegerLimitation.repeatedFields_, null);
};
goog.inherits(proto.dibric.graph.v1.IntegerLimitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.IntegerLimitation.displayName = 'proto.dibric.graph.v1.IntegerLimitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.IntegerLimitation.Interval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.IntegerLimitation.Interval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.IntegerLimitation.Interval.displayName = 'proto.dibric.graph.v1.IntegerLimitation.Interval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.DecimalLimitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.graph.v1.DecimalLimitation.repeatedFields_, null);
};
goog.inherits(proto.dibric.graph.v1.DecimalLimitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.DecimalLimitation.displayName = 'proto.dibric.graph.v1.DecimalLimitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.DecimalLimitation.Border = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.DecimalLimitation.Border, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.DecimalLimitation.Border.displayName = 'proto.dibric.graph.v1.DecimalLimitation.Border';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.DecimalLimitation.Interval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.DecimalLimitation.Interval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.DecimalLimitation.Interval.displayName = 'proto.dibric.graph.v1.DecimalLimitation.Interval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.DatetimeLimitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dibric.graph.v1.DatetimeLimitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.DatetimeLimitation.displayName = 'proto.dibric.graph.v1.DatetimeLimitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dibric.graph.v1.StringComparison = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dibric.graph.v1.StringComparison.repeatedFields_, null);
};
goog.inherits(proto.dibric.graph.v1.StringComparison, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dibric.graph.v1.StringComparison.displayName = 'proto.dibric.graph.v1.StringComparison';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.NodePosition.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.NodePosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.NodePosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.NodePosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.NodePosition}
 */
proto.dibric.graph.v1.NodePosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.NodePosition;
  return proto.dibric.graph.v1.NodePosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.NodePosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.NodePosition}
 */
proto.dibric.graph.v1.NodePosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.NodePosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.NodePosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.NodePosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.NodePosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.dibric.graph.v1.NodePosition.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.NodePosition} returns this
 */
proto.dibric.graph.v1.NodePosition.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.dibric.graph.v1.NodePosition.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.NodePosition} returns this
 */
proto.dibric.graph.v1.NodePosition.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.Edge.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.Edge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.Edge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.Edge.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    target: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sourceHandle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    targetHandle: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.Edge}
 */
proto.dibric.graph.v1.Edge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.Edge;
  return proto.dibric.graph.v1.Edge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.Edge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.Edge}
 */
proto.dibric.graph.v1.Edge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceHandle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetHandle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.Edge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.Edge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.Edge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.Edge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceHandle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTargetHandle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.dibric.graph.v1.Edge.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Edge} returns this
 */
proto.dibric.graph.v1.Edge.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target = 2;
 * @return {string}
 */
proto.dibric.graph.v1.Edge.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Edge} returns this
 */
proto.dibric.graph.v1.Edge.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string source_handle = 3;
 * @return {string}
 */
proto.dibric.graph.v1.Edge.prototype.getSourceHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Edge} returns this
 */
proto.dibric.graph.v1.Edge.prototype.setSourceHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string target_handle = 4;
 * @return {string}
 */
proto.dibric.graph.v1.Edge.prototype.getTargetHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Edge} returns this
 */
proto.dibric.graph.v1.Edge.prototype.setTargetHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dibric.graph.v1.Value.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.dibric.graph.v1.Value.ValueCase = {
  VALUE_NOT_SET: 0,
  STRING: 1,
  DECIMAL: 2,
  INTEGER: 3,
  BOOLEAN: 4,
  DATETIME: 5,
  BINARY: 6
};

/**
 * @return {proto.dibric.graph.v1.Value.ValueCase}
 */
proto.dibric.graph.v1.Value.prototype.getValueCase = function() {
  return /** @type {proto.dibric.graph.v1.Value.ValueCase} */(jspb.Message.computeOneofCase(this, proto.dibric.graph.v1.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    string: jspb.Message.getFieldWithDefault(msg, 1, ""),
    decimal: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    integer: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pb_boolean: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    datetime: (f = msg.getDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    binary: (f = msg.getBinary()) && proto.dibric.graph.v1.Value.BinaryValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.Value}
 */
proto.dibric.graph.v1.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.Value;
  return proto.dibric.graph.v1.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.Value}
 */
proto.dibric.graph.v1.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setString(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDecimal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInteger(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolean(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDatetime(value);
      break;
    case 6:
      var value = new proto.dibric.graph.v1.Value.BinaryValue;
      reader.readMessage(value,proto.dibric.graph.v1.Value.BinaryValue.deserializeBinaryFromReader);
      msg.setBinary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDatetime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBinary();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.graph.v1.Value.BinaryValue.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.Value.BinaryValue.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.Value.BinaryValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.Value.BinaryValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.Value.BinaryValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: msg.getValue_asB64(),
    contentType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.Value.BinaryValue}
 */
proto.dibric.graph.v1.Value.BinaryValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.Value.BinaryValue;
  return proto.dibric.graph.v1.Value.BinaryValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.Value.BinaryValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.Value.BinaryValue}
 */
proto.dibric.graph.v1.Value.BinaryValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.Value.BinaryValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.Value.BinaryValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.Value.BinaryValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.Value.BinaryValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getContentType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes value = 1;
 * @return {string}
 */
proto.dibric.graph.v1.Value.BinaryValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes value = 1;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.dibric.graph.v1.Value.BinaryValue.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.Value.BinaryValue.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dibric.graph.v1.Value.BinaryValue} returns this
 */
proto.dibric.graph.v1.Value.BinaryValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string content_type = 2;
 * @return {string}
 */
proto.dibric.graph.v1.Value.BinaryValue.prototype.getContentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Value.BinaryValue} returns this
 */
proto.dibric.graph.v1.Value.BinaryValue.prototype.setContentType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string string = 1;
 * @return {string}
 */
proto.dibric.graph.v1.Value.prototype.getString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.setString = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.dibric.graph.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.clearString = function() {
  return jspb.Message.setOneofField(this, 1, proto.dibric.graph.v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.Value.prototype.hasString = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double decimal = 2;
 * @return {number}
 */
proto.dibric.graph.v1.Value.prototype.getDecimal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.setDecimal = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.dibric.graph.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.clearDecimal = function() {
  return jspb.Message.setOneofField(this, 2, proto.dibric.graph.v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.Value.prototype.hasDecimal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 integer = 3;
 * @return {number}
 */
proto.dibric.graph.v1.Value.prototype.getInteger = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.setInteger = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.dibric.graph.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.clearInteger = function() {
  return jspb.Message.setOneofField(this, 3, proto.dibric.graph.v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.Value.prototype.hasInteger = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool boolean = 4;
 * @return {boolean}
 */
proto.dibric.graph.v1.Value.prototype.getBoolean = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.setBoolean = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.dibric.graph.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.clearBoolean = function() {
  return jspb.Message.setOneofField(this, 4, proto.dibric.graph.v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.Value.prototype.hasBoolean = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp datetime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dibric.graph.v1.Value.prototype.getDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dibric.graph.v1.Value} returns this
*/
proto.dibric.graph.v1.Value.prototype.setDatetime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.dibric.graph.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.clearDatetime = function() {
  return this.setDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.Value.prototype.hasDatetime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BinaryValue binary = 6;
 * @return {?proto.dibric.graph.v1.Value.BinaryValue}
 */
proto.dibric.graph.v1.Value.prototype.getBinary = function() {
  return /** @type{?proto.dibric.graph.v1.Value.BinaryValue} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.Value.BinaryValue, 6));
};


/**
 * @param {?proto.dibric.graph.v1.Value.BinaryValue|undefined} value
 * @return {!proto.dibric.graph.v1.Value} returns this
*/
proto.dibric.graph.v1.Value.prototype.setBinary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.dibric.graph.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.Value} returns this
 */
proto.dibric.graph.v1.Value.prototype.clearBinary = function() {
  return this.setBinary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.Value.prototype.hasBinary = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.graph.v1.InputVariables.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.InputVariables.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.InputVariables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.InputVariables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.InputVariables.toObject = function(includeInstance, msg) {
  var f, obj = {
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    proto.dibric.graph.v1.InputVariable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.InputVariables}
 */
proto.dibric.graph.v1.InputVariables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.InputVariables;
  return proto.dibric.graph.v1.InputVariables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.InputVariables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.InputVariables}
 */
proto.dibric.graph.v1.InputVariables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.graph.v1.InputVariable;
      reader.readMessage(value,proto.dibric.graph.v1.InputVariable.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.InputVariables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.InputVariables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.InputVariables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.InputVariables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.graph.v1.InputVariable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InputVariable variables = 1;
 * @return {!Array<!proto.dibric.graph.v1.InputVariable>}
 */
proto.dibric.graph.v1.InputVariables.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.InputVariable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.graph.v1.InputVariable, 1));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.InputVariable>} value
 * @return {!proto.dibric.graph.v1.InputVariables} returns this
*/
proto.dibric.graph.v1.InputVariables.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.graph.v1.InputVariable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.InputVariable}
 */
proto.dibric.graph.v1.InputVariables.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.graph.v1.InputVariable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.graph.v1.InputVariables} returns this
 */
proto.dibric.graph.v1.InputVariables.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.graph.v1.OutputVariables.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.OutputVariables.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.OutputVariables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.OutputVariables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.OutputVariables.toObject = function(includeInstance, msg) {
  var f, obj = {
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    proto.dibric.graph.v1.Variable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.OutputVariables}
 */
proto.dibric.graph.v1.OutputVariables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.OutputVariables;
  return proto.dibric.graph.v1.OutputVariables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.OutputVariables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.OutputVariables}
 */
proto.dibric.graph.v1.OutputVariables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.graph.v1.Variable;
      reader.readMessage(value,proto.dibric.graph.v1.Variable.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.OutputVariables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.OutputVariables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.OutputVariables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.OutputVariables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.graph.v1.Variable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Variable variables = 1;
 * @return {!Array<!proto.dibric.graph.v1.Variable>}
 */
proto.dibric.graph.v1.OutputVariables.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.Variable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.graph.v1.Variable, 1));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.Variable>} value
 * @return {!proto.dibric.graph.v1.OutputVariables} returns this
*/
proto.dibric.graph.v1.OutputVariables.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.graph.v1.Variable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.Variable}
 */
proto.dibric.graph.v1.OutputVariables.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.graph.v1.Variable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.graph.v1.OutputVariables} returns this
 */
proto.dibric.graph.v1.OutputVariables.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dibric.graph.v1.NodeHandle.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.dibric.graph.v1.NodeHandle.VariablesCase = {
  VARIABLES_NOT_SET: 0,
  INPUT_VARIABLES: 5,
  OUTPUT_VARIABLES: 6
};

/**
 * @return {proto.dibric.graph.v1.NodeHandle.VariablesCase}
 */
proto.dibric.graph.v1.NodeHandle.prototype.getVariablesCase = function() {
  return /** @type {proto.dibric.graph.v1.NodeHandle.VariablesCase} */(jspb.Message.computeOneofCase(this, proto.dibric.graph.v1.NodeHandle.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.NodeHandle.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.NodeHandle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.NodeHandle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.NodeHandle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    inputVariables: (f = msg.getInputVariables()) && proto.dibric.graph.v1.InputVariables.toObject(includeInstance, f),
    outputVariables: (f = msg.getOutputVariables()) && proto.dibric.graph.v1.OutputVariables.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.NodeHandle}
 */
proto.dibric.graph.v1.NodeHandle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.NodeHandle;
  return proto.dibric.graph.v1.NodeHandle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.NodeHandle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.NodeHandle}
 */
proto.dibric.graph.v1.NodeHandle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.dibric.graph.v1.HandleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.dibric.graph.v1.InputVariables;
      reader.readMessage(value,proto.dibric.graph.v1.InputVariables.deserializeBinaryFromReader);
      msg.setInputVariables(value);
      break;
    case 6:
      var value = new proto.dibric.graph.v1.OutputVariables;
      reader.readMessage(value,proto.dibric.graph.v1.OutputVariables.deserializeBinaryFromReader);
      msg.setOutputVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.NodeHandle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.NodeHandle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.NodeHandle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.NodeHandle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getInputVariables();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dibric.graph.v1.InputVariables.serializeBinaryToWriter
    );
  }
  f = message.getOutputVariables();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.graph.v1.OutputVariables.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.graph.v1.NodeHandle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.NodeHandle} returns this
 */
proto.dibric.graph.v1.NodeHandle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.dibric.graph.v1.NodeHandle.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.NodeHandle} returns this
 */
proto.dibric.graph.v1.NodeHandle.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dibric.graph.v1.NodeHandle.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.NodeHandle} returns this
 */
proto.dibric.graph.v1.NodeHandle.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional HandleType type = 4;
 * @return {!proto.dibric.graph.v1.HandleType}
 */
proto.dibric.graph.v1.NodeHandle.prototype.getType = function() {
  return /** @type {!proto.dibric.graph.v1.HandleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.dibric.graph.v1.HandleType} value
 * @return {!proto.dibric.graph.v1.NodeHandle} returns this
 */
proto.dibric.graph.v1.NodeHandle.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional InputVariables input_variables = 5;
 * @return {?proto.dibric.graph.v1.InputVariables}
 */
proto.dibric.graph.v1.NodeHandle.prototype.getInputVariables = function() {
  return /** @type{?proto.dibric.graph.v1.InputVariables} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.InputVariables, 5));
};


/**
 * @param {?proto.dibric.graph.v1.InputVariables|undefined} value
 * @return {!proto.dibric.graph.v1.NodeHandle} returns this
*/
proto.dibric.graph.v1.NodeHandle.prototype.setInputVariables = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.dibric.graph.v1.NodeHandle.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.NodeHandle} returns this
 */
proto.dibric.graph.v1.NodeHandle.prototype.clearInputVariables = function() {
  return this.setInputVariables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.NodeHandle.prototype.hasInputVariables = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional OutputVariables output_variables = 6;
 * @return {?proto.dibric.graph.v1.OutputVariables}
 */
proto.dibric.graph.v1.NodeHandle.prototype.getOutputVariables = function() {
  return /** @type{?proto.dibric.graph.v1.OutputVariables} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.OutputVariables, 6));
};


/**
 * @param {?proto.dibric.graph.v1.OutputVariables|undefined} value
 * @return {!proto.dibric.graph.v1.NodeHandle} returns this
*/
proto.dibric.graph.v1.NodeHandle.prototype.setOutputVariables = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.dibric.graph.v1.NodeHandle.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.NodeHandle} returns this
 */
proto.dibric.graph.v1.NodeHandle.prototype.clearOutputVariables = function() {
  return this.setOutputVariables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.NodeHandle.prototype.hasOutputVariables = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.VariableKey.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.VariableKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.VariableKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.VariableKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    handleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    variableId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.VariableKey}
 */
proto.dibric.graph.v1.VariableKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.VariableKey;
  return proto.dibric.graph.v1.VariableKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.VariableKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.VariableKey}
 */
proto.dibric.graph.v1.VariableKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariableId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.VariableKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.VariableKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.VariableKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.VariableKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHandleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVariableId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string node_id = 1;
 * @return {string}
 */
proto.dibric.graph.v1.VariableKey.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.VariableKey} returns this
 */
proto.dibric.graph.v1.VariableKey.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string handle_id = 2;
 * @return {string}
 */
proto.dibric.graph.v1.VariableKey.prototype.getHandleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.VariableKey} returns this
 */
proto.dibric.graph.v1.VariableKey.prototype.setHandleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string variable_id = 3;
 * @return {string}
 */
proto.dibric.graph.v1.VariableKey.prototype.getVariableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.VariableKey} returns this
 */
proto.dibric.graph.v1.VariableKey.prototype.setVariableId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.KeyValue.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.KeyValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.KeyValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.KeyValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = msg.getKey()) && proto.dibric.graph.v1.VariableKey.toObject(includeInstance, f),
    value: (f = msg.getValue()) && proto.dibric.graph.v1.Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.KeyValue}
 */
proto.dibric.graph.v1.KeyValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.KeyValue;
  return proto.dibric.graph.v1.KeyValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.KeyValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.KeyValue}
 */
proto.dibric.graph.v1.KeyValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.graph.v1.VariableKey;
      reader.readMessage(value,proto.dibric.graph.v1.VariableKey.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.dibric.graph.v1.Value;
      reader.readMessage(value,proto.dibric.graph.v1.Value.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.KeyValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.KeyValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.KeyValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.KeyValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.graph.v1.VariableKey.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dibric.graph.v1.Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional VariableKey key = 1;
 * @return {?proto.dibric.graph.v1.VariableKey}
 */
proto.dibric.graph.v1.KeyValue.prototype.getKey = function() {
  return /** @type{?proto.dibric.graph.v1.VariableKey} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.VariableKey, 1));
};


/**
 * @param {?proto.dibric.graph.v1.VariableKey|undefined} value
 * @return {!proto.dibric.graph.v1.KeyValue} returns this
*/
proto.dibric.graph.v1.KeyValue.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.KeyValue} returns this
 */
proto.dibric.graph.v1.KeyValue.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.KeyValue.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Value value = 2;
 * @return {?proto.dibric.graph.v1.Value}
 */
proto.dibric.graph.v1.KeyValue.prototype.getValue = function() {
  return /** @type{?proto.dibric.graph.v1.Value} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.Value, 2));
};


/**
 * @param {?proto.dibric.graph.v1.Value|undefined} value
 * @return {!proto.dibric.graph.v1.KeyValue} returns this
*/
proto.dibric.graph.v1.KeyValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.KeyValue} returns this
 */
proto.dibric.graph.v1.KeyValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.KeyValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.InputVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.InputVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.InputVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.InputVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    variable: (f = msg.getVariable()) && proto.dibric.graph.v1.Variable.toObject(includeInstance, f),
    connectedTo: (f = msg.getConnectedTo()) && proto.dibric.graph.v1.VariableKey.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.InputVariable}
 */
proto.dibric.graph.v1.InputVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.InputVariable;
  return proto.dibric.graph.v1.InputVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.InputVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.InputVariable}
 */
proto.dibric.graph.v1.InputVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.graph.v1.Variable;
      reader.readMessage(value,proto.dibric.graph.v1.Variable.deserializeBinaryFromReader);
      msg.setVariable(value);
      break;
    case 2:
      var value = new proto.dibric.graph.v1.VariableKey;
      reader.readMessage(value,proto.dibric.graph.v1.VariableKey.deserializeBinaryFromReader);
      msg.setConnectedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.InputVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.InputVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.InputVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.InputVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.graph.v1.Variable.serializeBinaryToWriter
    );
  }
  f = message.getConnectedTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dibric.graph.v1.VariableKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional Variable variable = 1;
 * @return {?proto.dibric.graph.v1.Variable}
 */
proto.dibric.graph.v1.InputVariable.prototype.getVariable = function() {
  return /** @type{?proto.dibric.graph.v1.Variable} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.Variable, 1));
};


/**
 * @param {?proto.dibric.graph.v1.Variable|undefined} value
 * @return {!proto.dibric.graph.v1.InputVariable} returns this
*/
proto.dibric.graph.v1.InputVariable.prototype.setVariable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.InputVariable} returns this
 */
proto.dibric.graph.v1.InputVariable.prototype.clearVariable = function() {
  return this.setVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.InputVariable.prototype.hasVariable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional VariableKey connected_to = 2;
 * @return {?proto.dibric.graph.v1.VariableKey}
 */
proto.dibric.graph.v1.InputVariable.prototype.getConnectedTo = function() {
  return /** @type{?proto.dibric.graph.v1.VariableKey} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.VariableKey, 2));
};


/**
 * @param {?proto.dibric.graph.v1.VariableKey|undefined} value
 * @return {!proto.dibric.graph.v1.InputVariable} returns this
*/
proto.dibric.graph.v1.InputVariable.prototype.setConnectedTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.InputVariable} returns this
 */
proto.dibric.graph.v1.InputVariable.prototype.clearConnectedTo = function() {
  return this.setConnectedTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.InputVariable.prototype.hasConnectedTo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.Variable.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.Variable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.Variable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.Variable.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    optional: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accepting: (f = msg.getAccepting()) && proto.dibric.graph.v1.TypeDescription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.Variable}
 */
proto.dibric.graph.v1.Variable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.Variable;
  return proto.dibric.graph.v1.Variable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.Variable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.Variable}
 */
proto.dibric.graph.v1.Variable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptional(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto.dibric.graph.v1.TypeDescription;
      reader.readMessage(value,proto.dibric.graph.v1.TypeDescription.deserializeBinaryFromReader);
      msg.setAccepting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.Variable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.Variable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.Variable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.Variable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptional();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccepting();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dibric.graph.v1.TypeDescription.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dibric.graph.v1.Variable.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Variable} returns this
 */
proto.dibric.graph.v1.Variable.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool optional = 2;
 * @return {boolean}
 */
proto.dibric.graph.v1.Variable.prototype.getOptional = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.graph.v1.Variable} returns this
 */
proto.dibric.graph.v1.Variable.prototype.setOptional = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.dibric.graph.v1.Variable.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Variable} returns this
 */
proto.dibric.graph.v1.Variable.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.dibric.graph.v1.Variable.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dibric.graph.v1.Variable} returns this
 */
proto.dibric.graph.v1.Variable.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional TypeDescription accepting = 5;
 * @return {?proto.dibric.graph.v1.TypeDescription}
 */
proto.dibric.graph.v1.Variable.prototype.getAccepting = function() {
  return /** @type{?proto.dibric.graph.v1.TypeDescription} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.TypeDescription, 5));
};


/**
 * @param {?proto.dibric.graph.v1.TypeDescription|undefined} value
 * @return {!proto.dibric.graph.v1.Variable} returns this
*/
proto.dibric.graph.v1.Variable.prototype.setAccepting = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.Variable} returns this
 */
proto.dibric.graph.v1.Variable.prototype.clearAccepting = function() {
  return this.setAccepting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.Variable.prototype.hasAccepting = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dibric.graph.v1.TypeDescription.oneofGroups_ = [[2,3,4,5,6],[7,8,9,10,11,12]];

/**
 * @enum {number}
 */
proto.dibric.graph.v1.TypeDescription.LimitationCase = {
  LIMITATION_NOT_SET: 0,
  STRING_LIMITATION: 2,
  DATETIME_LIMITATION: 3,
  DECIMAL_LIMITATION: 4,
  INTEGER_LIMITATION: 5,
  BINARY_LIMITATION: 6
};

/**
 * @return {proto.dibric.graph.v1.TypeDescription.LimitationCase}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getLimitationCase = function() {
  return /** @type {proto.dibric.graph.v1.TypeDescription.LimitationCase} */(jspb.Message.computeOneofCase(this, proto.dibric.graph.v1.TypeDescription.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.dibric.graph.v1.TypeDescription.ExtendedTypeCase = {
  EXTENDED_TYPE_NOT_SET: 0,
  STRING: 7,
  DECIMAL: 8,
  INTEGER: 9,
  BOOLEAN: 10,
  DATETIME: 11,
  BINARY: 12
};

/**
 * @return {proto.dibric.graph.v1.TypeDescription.ExtendedTypeCase}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getExtendedTypeCase = function() {
  return /** @type {proto.dibric.graph.v1.TypeDescription.ExtendedTypeCase} */(jspb.Message.computeOneofCase(this, proto.dibric.graph.v1.TypeDescription.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.TypeDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.TypeDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.TypeDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.TypeDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stringLimitation: (f = msg.getStringLimitation()) && proto.dibric.graph.v1.StringLimitation.toObject(includeInstance, f),
    datetimeLimitation: (f = msg.getDatetimeLimitation()) && proto.dibric.graph.v1.DatetimeLimitation.toObject(includeInstance, f),
    decimalLimitation: (f = msg.getDecimalLimitation()) && proto.dibric.graph.v1.DecimalLimitation.toObject(includeInstance, f),
    integerLimitation: (f = msg.getIntegerLimitation()) && proto.dibric.graph.v1.IntegerLimitation.toObject(includeInstance, f),
    binaryLimitation: (f = msg.getBinaryLimitation()) && proto.dibric.graph.v1.BinaryLimitation.toObject(includeInstance, f),
    string: (f = msg.getString()) && proto.dibric.graph.v1.StringType.toObject(includeInstance, f),
    decimal: (f = msg.getDecimal()) && proto.dibric.graph.v1.DecimalType.toObject(includeInstance, f),
    integer: (f = msg.getInteger()) && proto.dibric.graph.v1.IntegerType.toObject(includeInstance, f),
    pb_boolean: (f = msg.getBoolean()) && proto.dibric.graph.v1.BooleanType.toObject(includeInstance, f),
    datetime: (f = msg.getDatetime()) && proto.dibric.graph.v1.DatetimeType.toObject(includeInstance, f),
    binary: (f = msg.getBinary()) && proto.dibric.graph.v1.BinaryType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.TypeDescription}
 */
proto.dibric.graph.v1.TypeDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.TypeDescription;
  return proto.dibric.graph.v1.TypeDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.TypeDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.TypeDescription}
 */
proto.dibric.graph.v1.TypeDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dibric.graph.v1.ValueType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.dibric.graph.v1.StringLimitation;
      reader.readMessage(value,proto.dibric.graph.v1.StringLimitation.deserializeBinaryFromReader);
      msg.setStringLimitation(value);
      break;
    case 3:
      var value = new proto.dibric.graph.v1.DatetimeLimitation;
      reader.readMessage(value,proto.dibric.graph.v1.DatetimeLimitation.deserializeBinaryFromReader);
      msg.setDatetimeLimitation(value);
      break;
    case 4:
      var value = new proto.dibric.graph.v1.DecimalLimitation;
      reader.readMessage(value,proto.dibric.graph.v1.DecimalLimitation.deserializeBinaryFromReader);
      msg.setDecimalLimitation(value);
      break;
    case 5:
      var value = new proto.dibric.graph.v1.IntegerLimitation;
      reader.readMessage(value,proto.dibric.graph.v1.IntegerLimitation.deserializeBinaryFromReader);
      msg.setIntegerLimitation(value);
      break;
    case 6:
      var value = new proto.dibric.graph.v1.BinaryLimitation;
      reader.readMessage(value,proto.dibric.graph.v1.BinaryLimitation.deserializeBinaryFromReader);
      msg.setBinaryLimitation(value);
      break;
    case 7:
      var value = new proto.dibric.graph.v1.StringType;
      reader.readMessage(value,proto.dibric.graph.v1.StringType.deserializeBinaryFromReader);
      msg.setString(value);
      break;
    case 8:
      var value = new proto.dibric.graph.v1.DecimalType;
      reader.readMessage(value,proto.dibric.graph.v1.DecimalType.deserializeBinaryFromReader);
      msg.setDecimal(value);
      break;
    case 9:
      var value = new proto.dibric.graph.v1.IntegerType;
      reader.readMessage(value,proto.dibric.graph.v1.IntegerType.deserializeBinaryFromReader);
      msg.setInteger(value);
      break;
    case 10:
      var value = new proto.dibric.graph.v1.BooleanType;
      reader.readMessage(value,proto.dibric.graph.v1.BooleanType.deserializeBinaryFromReader);
      msg.setBoolean(value);
      break;
    case 11:
      var value = new proto.dibric.graph.v1.DatetimeType;
      reader.readMessage(value,proto.dibric.graph.v1.DatetimeType.deserializeBinaryFromReader);
      msg.setDatetime(value);
      break;
    case 12:
      var value = new proto.dibric.graph.v1.BinaryType;
      reader.readMessage(value,proto.dibric.graph.v1.BinaryType.deserializeBinaryFromReader);
      msg.setBinary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.TypeDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.TypeDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.TypeDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.TypeDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStringLimitation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dibric.graph.v1.StringLimitation.serializeBinaryToWriter
    );
  }
  f = message.getDatetimeLimitation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dibric.graph.v1.DatetimeLimitation.serializeBinaryToWriter
    );
  }
  f = message.getDecimalLimitation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dibric.graph.v1.DecimalLimitation.serializeBinaryToWriter
    );
  }
  f = message.getIntegerLimitation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dibric.graph.v1.IntegerLimitation.serializeBinaryToWriter
    );
  }
  f = message.getBinaryLimitation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.graph.v1.BinaryLimitation.serializeBinaryToWriter
    );
  }
  f = message.getString();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.dibric.graph.v1.StringType.serializeBinaryToWriter
    );
  }
  f = message.getDecimal();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.dibric.graph.v1.DecimalType.serializeBinaryToWriter
    );
  }
  f = message.getInteger();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.dibric.graph.v1.IntegerType.serializeBinaryToWriter
    );
  }
  f = message.getBoolean();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.dibric.graph.v1.BooleanType.serializeBinaryToWriter
    );
  }
  f = message.getDatetime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.dibric.graph.v1.DatetimeType.serializeBinaryToWriter
    );
  }
  f = message.getBinary();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.dibric.graph.v1.BinaryType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ValueType type = 1;
 * @return {!proto.dibric.graph.v1.ValueType}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getType = function() {
  return /** @type {!proto.dibric.graph.v1.ValueType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dibric.graph.v1.ValueType} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional StringLimitation string_limitation = 2;
 * @return {?proto.dibric.graph.v1.StringLimitation}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getStringLimitation = function() {
  return /** @type{?proto.dibric.graph.v1.StringLimitation} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.StringLimitation, 2));
};


/**
 * @param {?proto.dibric.graph.v1.StringLimitation|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setStringLimitation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.dibric.graph.v1.TypeDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearStringLimitation = function() {
  return this.setStringLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasStringLimitation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DatetimeLimitation datetime_limitation = 3;
 * @return {?proto.dibric.graph.v1.DatetimeLimitation}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getDatetimeLimitation = function() {
  return /** @type{?proto.dibric.graph.v1.DatetimeLimitation} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.DatetimeLimitation, 3));
};


/**
 * @param {?proto.dibric.graph.v1.DatetimeLimitation|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setDatetimeLimitation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.dibric.graph.v1.TypeDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearDatetimeLimitation = function() {
  return this.setDatetimeLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasDatetimeLimitation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DecimalLimitation decimal_limitation = 4;
 * @return {?proto.dibric.graph.v1.DecimalLimitation}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getDecimalLimitation = function() {
  return /** @type{?proto.dibric.graph.v1.DecimalLimitation} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.DecimalLimitation, 4));
};


/**
 * @param {?proto.dibric.graph.v1.DecimalLimitation|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setDecimalLimitation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.dibric.graph.v1.TypeDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearDecimalLimitation = function() {
  return this.setDecimalLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasDecimalLimitation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional IntegerLimitation integer_limitation = 5;
 * @return {?proto.dibric.graph.v1.IntegerLimitation}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getIntegerLimitation = function() {
  return /** @type{?proto.dibric.graph.v1.IntegerLimitation} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.IntegerLimitation, 5));
};


/**
 * @param {?proto.dibric.graph.v1.IntegerLimitation|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setIntegerLimitation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.dibric.graph.v1.TypeDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearIntegerLimitation = function() {
  return this.setIntegerLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasIntegerLimitation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BinaryLimitation binary_limitation = 6;
 * @return {?proto.dibric.graph.v1.BinaryLimitation}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getBinaryLimitation = function() {
  return /** @type{?proto.dibric.graph.v1.BinaryLimitation} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.BinaryLimitation, 6));
};


/**
 * @param {?proto.dibric.graph.v1.BinaryLimitation|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setBinaryLimitation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.dibric.graph.v1.TypeDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearBinaryLimitation = function() {
  return this.setBinaryLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasBinaryLimitation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional StringType string = 7;
 * @return {?proto.dibric.graph.v1.StringType}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getString = function() {
  return /** @type{?proto.dibric.graph.v1.StringType} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.StringType, 7));
};


/**
 * @param {?proto.dibric.graph.v1.StringType|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setString = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.dibric.graph.v1.TypeDescription.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearString = function() {
  return this.setString(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasString = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DecimalType decimal = 8;
 * @return {?proto.dibric.graph.v1.DecimalType}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getDecimal = function() {
  return /** @type{?proto.dibric.graph.v1.DecimalType} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.DecimalType, 8));
};


/**
 * @param {?proto.dibric.graph.v1.DecimalType|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setDecimal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.dibric.graph.v1.TypeDescription.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearDecimal = function() {
  return this.setDecimal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasDecimal = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional IntegerType integer = 9;
 * @return {?proto.dibric.graph.v1.IntegerType}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getInteger = function() {
  return /** @type{?proto.dibric.graph.v1.IntegerType} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.IntegerType, 9));
};


/**
 * @param {?proto.dibric.graph.v1.IntegerType|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setInteger = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.dibric.graph.v1.TypeDescription.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearInteger = function() {
  return this.setInteger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasInteger = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional BooleanType boolean = 10;
 * @return {?proto.dibric.graph.v1.BooleanType}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getBoolean = function() {
  return /** @type{?proto.dibric.graph.v1.BooleanType} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.BooleanType, 10));
};


/**
 * @param {?proto.dibric.graph.v1.BooleanType|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setBoolean = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.dibric.graph.v1.TypeDescription.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearBoolean = function() {
  return this.setBoolean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasBoolean = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DatetimeType datetime = 11;
 * @return {?proto.dibric.graph.v1.DatetimeType}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getDatetime = function() {
  return /** @type{?proto.dibric.graph.v1.DatetimeType} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.DatetimeType, 11));
};


/**
 * @param {?proto.dibric.graph.v1.DatetimeType|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setDatetime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.dibric.graph.v1.TypeDescription.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearDatetime = function() {
  return this.setDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasDatetime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional BinaryType binary = 12;
 * @return {?proto.dibric.graph.v1.BinaryType}
 */
proto.dibric.graph.v1.TypeDescription.prototype.getBinary = function() {
  return /** @type{?proto.dibric.graph.v1.BinaryType} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.BinaryType, 12));
};


/**
 * @param {?proto.dibric.graph.v1.BinaryType|undefined} value
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
*/
proto.dibric.graph.v1.TypeDescription.prototype.setBinary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.dibric.graph.v1.TypeDescription.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.TypeDescription} returns this
 */
proto.dibric.graph.v1.TypeDescription.prototype.clearBinary = function() {
  return this.setBinary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.TypeDescription.prototype.hasBinary = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.StringType.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.StringType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.StringType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.StringType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.StringType}
 */
proto.dibric.graph.v1.StringType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.StringType;
  return proto.dibric.graph.v1.StringType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.StringType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.StringType}
 */
proto.dibric.graph.v1.StringType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.StringType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.StringType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.StringType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.StringType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.DecimalType.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.DecimalType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.DecimalType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DecimalType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.DecimalType}
 */
proto.dibric.graph.v1.DecimalType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.DecimalType;
  return proto.dibric.graph.v1.DecimalType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.DecimalType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.DecimalType}
 */
proto.dibric.graph.v1.DecimalType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.DecimalType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.DecimalType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.DecimalType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DecimalType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.IntegerType.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.IntegerType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.IntegerType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.IntegerType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.IntegerType}
 */
proto.dibric.graph.v1.IntegerType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.IntegerType;
  return proto.dibric.graph.v1.IntegerType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.IntegerType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.IntegerType}
 */
proto.dibric.graph.v1.IntegerType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.IntegerType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.IntegerType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.IntegerType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.IntegerType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.BooleanType.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.BooleanType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.BooleanType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.BooleanType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.BooleanType}
 */
proto.dibric.graph.v1.BooleanType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.BooleanType;
  return proto.dibric.graph.v1.BooleanType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.BooleanType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.BooleanType}
 */
proto.dibric.graph.v1.BooleanType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.BooleanType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.BooleanType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.BooleanType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.BooleanType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.DatetimeType.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.DatetimeType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.DatetimeType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DatetimeType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.DatetimeType}
 */
proto.dibric.graph.v1.DatetimeType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.DatetimeType;
  return proto.dibric.graph.v1.DatetimeType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.DatetimeType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.DatetimeType}
 */
proto.dibric.graph.v1.DatetimeType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.DatetimeType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.DatetimeType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.DatetimeType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DatetimeType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.BinaryType.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.BinaryType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.BinaryType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.BinaryType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.BinaryType}
 */
proto.dibric.graph.v1.BinaryType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.BinaryType;
  return proto.dibric.graph.v1.BinaryType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.BinaryType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.BinaryType}
 */
proto.dibric.graph.v1.BinaryType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.BinaryType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.BinaryType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.BinaryType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.BinaryType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.BinaryLimitation.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.BinaryLimitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.BinaryLimitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.BinaryLimitation.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.BinaryLimitation}
 */
proto.dibric.graph.v1.BinaryLimitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.BinaryLimitation;
  return proto.dibric.graph.v1.BinaryLimitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.BinaryLimitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.BinaryLimitation}
 */
proto.dibric.graph.v1.BinaryLimitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.BinaryLimitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.BinaryLimitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.BinaryLimitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.BinaryLimitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.graph.v1.StringLimitation.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.StringLimitation.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.StringLimitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.StringLimitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.StringLimitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    patternsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    maxLength: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minLength: jspb.Message.getFieldWithDefault(msg, 3, 0),
    restrictionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    mustContain: (f = msg.getMustContain()) && proto.dibric.graph.v1.StringComparison.toObject(includeInstance, f),
    mustNotContain: (f = msg.getMustNotContain()) && proto.dibric.graph.v1.StringComparison.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.StringLimitation}
 */
proto.dibric.graph.v1.StringLimitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.StringLimitation;
  return proto.dibric.graph.v1.StringLimitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.StringLimitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.StringLimitation}
 */
proto.dibric.graph.v1.StringLimitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPatterns(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxLength(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinLength(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.dibric.graph.v1.StringLimitation.ValueRestriction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRestrictions(values[i]);
      }
      break;
    case 5:
      var value = new proto.dibric.graph.v1.StringComparison;
      reader.readMessage(value,proto.dibric.graph.v1.StringComparison.deserializeBinaryFromReader);
      msg.setMustContain(value);
      break;
    case 6:
      var value = new proto.dibric.graph.v1.StringComparison;
      reader.readMessage(value,proto.dibric.graph.v1.StringComparison.deserializeBinaryFromReader);
      msg.setMustNotContain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.StringLimitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.StringLimitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.StringLimitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.StringLimitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatternsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getMaxLength();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMinLength();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRestrictionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getMustContain();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dibric.graph.v1.StringComparison.serializeBinaryToWriter
    );
  }
  f = message.getMustNotContain();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dibric.graph.v1.StringComparison.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.graph.v1.StringLimitation.ValueRestriction = {
  UNKNOWN: 0,
  EMAIL: 1,
  IBAN: 2,
  BIC: 3,
  UPPERCASE: 4,
  LOWERCASE: 5,
  ABSOLUTE_URI: 6,
  NON_EMPTY_OR_WHITESPACE: 7,
  CREDIT_CARD: 8,
  SWEDISH_PNR: 9,
  IPV4: 10,
  IPV6: 12,
  UUID: 13,
  URL: 14
};

/**
 * repeated string patterns = 1;
 * @return {!Array<string>}
 */
proto.dibric.graph.v1.StringLimitation.prototype.getPatternsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.setPatternsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.addPatterns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.clearPatternsList = function() {
  return this.setPatternsList([]);
};


/**
 * optional int32 max_length = 2;
 * @return {number}
 */
proto.dibric.graph.v1.StringLimitation.prototype.getMaxLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.setMaxLength = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 min_length = 3;
 * @return {number}
 */
proto.dibric.graph.v1.StringLimitation.prototype.getMinLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.setMinLength = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ValueRestriction restrictions = 4;
 * @return {!Array<!proto.dibric.graph.v1.StringLimitation.ValueRestriction>}
 */
proto.dibric.graph.v1.StringLimitation.prototype.getRestrictionsList = function() {
  return /** @type {!Array<!proto.dibric.graph.v1.StringLimitation.ValueRestriction>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.StringLimitation.ValueRestriction>} value
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.setRestrictionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.dibric.graph.v1.StringLimitation.ValueRestriction} value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.addRestrictions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.clearRestrictionsList = function() {
  return this.setRestrictionsList([]);
};


/**
 * optional StringComparison must_contain = 5;
 * @return {?proto.dibric.graph.v1.StringComparison}
 */
proto.dibric.graph.v1.StringLimitation.prototype.getMustContain = function() {
  return /** @type{?proto.dibric.graph.v1.StringComparison} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.StringComparison, 5));
};


/**
 * @param {?proto.dibric.graph.v1.StringComparison|undefined} value
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
*/
proto.dibric.graph.v1.StringLimitation.prototype.setMustContain = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.clearMustContain = function() {
  return this.setMustContain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.StringLimitation.prototype.hasMustContain = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional StringComparison must_not_contain = 6;
 * @return {?proto.dibric.graph.v1.StringComparison}
 */
proto.dibric.graph.v1.StringLimitation.prototype.getMustNotContain = function() {
  return /** @type{?proto.dibric.graph.v1.StringComparison} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.StringComparison, 6));
};


/**
 * @param {?proto.dibric.graph.v1.StringComparison|undefined} value
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
*/
proto.dibric.graph.v1.StringLimitation.prototype.setMustNotContain = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.StringLimitation} returns this
 */
proto.dibric.graph.v1.StringLimitation.prototype.clearMustNotContain = function() {
  return this.setMustNotContain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.StringLimitation.prototype.hasMustNotContain = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.graph.v1.IntegerLimitation.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.IntegerLimitation.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.IntegerLimitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.IntegerLimitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.IntegerLimitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    intervalsList: jspb.Message.toObjectList(msg.getIntervalsList(),
    proto.dibric.graph.v1.IntegerLimitation.Interval.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.IntegerLimitation}
 */
proto.dibric.graph.v1.IntegerLimitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.IntegerLimitation;
  return proto.dibric.graph.v1.IntegerLimitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.IntegerLimitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.IntegerLimitation}
 */
proto.dibric.graph.v1.IntegerLimitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.graph.v1.IntegerLimitation.Interval;
      reader.readMessage(value,proto.dibric.graph.v1.IntegerLimitation.Interval.deserializeBinaryFromReader);
      msg.addIntervals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.IntegerLimitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.IntegerLimitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.IntegerLimitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.IntegerLimitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntervalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.graph.v1.IntegerLimitation.Interval.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.IntegerLimitation.Interval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.IntegerLimitation.Interval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFieldWithDefault(msg, 1, 0),
    max: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.IntegerLimitation.Interval}
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.IntegerLimitation.Interval;
  return proto.dibric.graph.v1.IntegerLimitation.Interval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.IntegerLimitation.Interval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.IntegerLimitation.Interval}
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.IntegerLimitation.Interval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.IntegerLimitation.Interval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 min = 1;
 * @return {number}
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.IntegerLimitation.Interval} returns this
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 max = 2;
 * @return {number}
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.IntegerLimitation.Interval} returns this
 */
proto.dibric.graph.v1.IntegerLimitation.Interval.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Interval intervals = 1;
 * @return {!Array<!proto.dibric.graph.v1.IntegerLimitation.Interval>}
 */
proto.dibric.graph.v1.IntegerLimitation.prototype.getIntervalsList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.IntegerLimitation.Interval>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.graph.v1.IntegerLimitation.Interval, 1));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.IntegerLimitation.Interval>} value
 * @return {!proto.dibric.graph.v1.IntegerLimitation} returns this
*/
proto.dibric.graph.v1.IntegerLimitation.prototype.setIntervalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.graph.v1.IntegerLimitation.Interval=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.IntegerLimitation.Interval}
 */
proto.dibric.graph.v1.IntegerLimitation.prototype.addIntervals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.graph.v1.IntegerLimitation.Interval, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.graph.v1.IntegerLimitation} returns this
 */
proto.dibric.graph.v1.IntegerLimitation.prototype.clearIntervalsList = function() {
  return this.setIntervalsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.graph.v1.DecimalLimitation.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.DecimalLimitation.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.DecimalLimitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.DecimalLimitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DecimalLimitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    intervalsList: jspb.Message.toObjectList(msg.getIntervalsList(),
    proto.dibric.graph.v1.DecimalLimitation.Interval.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.DecimalLimitation}
 */
proto.dibric.graph.v1.DecimalLimitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.DecimalLimitation;
  return proto.dibric.graph.v1.DecimalLimitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.DecimalLimitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.DecimalLimitation}
 */
proto.dibric.graph.v1.DecimalLimitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.graph.v1.DecimalLimitation.Interval;
      reader.readMessage(value,proto.dibric.graph.v1.DecimalLimitation.Interval.deserializeBinaryFromReader);
      msg.addIntervals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.DecimalLimitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.DecimalLimitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.DecimalLimitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DecimalLimitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntervalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dibric.graph.v1.DecimalLimitation.Interval.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.DecimalLimitation.Border.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.DecimalLimitation.Border.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.DecimalLimitation.Border} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DecimalLimitation.Border.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    exclusive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Border}
 */
proto.dibric.graph.v1.DecimalLimitation.Border.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.DecimalLimitation.Border;
  return proto.dibric.graph.v1.DecimalLimitation.Border.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.DecimalLimitation.Border} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Border}
 */
proto.dibric.graph.v1.DecimalLimitation.Border.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExclusive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.DecimalLimitation.Border.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.DecimalLimitation.Border.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.DecimalLimitation.Border} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DecimalLimitation.Border.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getExclusive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.dibric.graph.v1.DecimalLimitation.Border.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Border} returns this
 */
proto.dibric.graph.v1.DecimalLimitation.Border.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional bool exclusive = 2;
 * @return {boolean}
 */
proto.dibric.graph.v1.DecimalLimitation.Border.prototype.getExclusive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Border} returns this
 */
proto.dibric.graph.v1.DecimalLimitation.Border.prototype.setExclusive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.DecimalLimitation.Interval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.DecimalLimitation.Interval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: (f = msg.getMin()) && proto.dibric.graph.v1.DecimalLimitation.Border.toObject(includeInstance, f),
    max: (f = msg.getMax()) && proto.dibric.graph.v1.DecimalLimitation.Border.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Interval}
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.DecimalLimitation.Interval;
  return proto.dibric.graph.v1.DecimalLimitation.Interval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.DecimalLimitation.Interval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Interval}
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dibric.graph.v1.DecimalLimitation.Border;
      reader.readMessage(value,proto.dibric.graph.v1.DecimalLimitation.Border.deserializeBinaryFromReader);
      msg.setMin(value);
      break;
    case 2:
      var value = new proto.dibric.graph.v1.DecimalLimitation.Border;
      reader.readMessage(value,proto.dibric.graph.v1.DecimalLimitation.Border.deserializeBinaryFromReader);
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.DecimalLimitation.Interval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.DecimalLimitation.Interval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dibric.graph.v1.DecimalLimitation.Border.serializeBinaryToWriter
    );
  }
  f = message.getMax();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dibric.graph.v1.DecimalLimitation.Border.serializeBinaryToWriter
    );
  }
};


/**
 * optional Border min = 1;
 * @return {?proto.dibric.graph.v1.DecimalLimitation.Border}
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.getMin = function() {
  return /** @type{?proto.dibric.graph.v1.DecimalLimitation.Border} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.DecimalLimitation.Border, 1));
};


/**
 * @param {?proto.dibric.graph.v1.DecimalLimitation.Border|undefined} value
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Interval} returns this
*/
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.setMin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Interval} returns this
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.clearMin = function() {
  return this.setMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.hasMin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Border max = 2;
 * @return {?proto.dibric.graph.v1.DecimalLimitation.Border}
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.getMax = function() {
  return /** @type{?proto.dibric.graph.v1.DecimalLimitation.Border} */ (
    jspb.Message.getWrapperField(this, proto.dibric.graph.v1.DecimalLimitation.Border, 2));
};


/**
 * @param {?proto.dibric.graph.v1.DecimalLimitation.Border|undefined} value
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Interval} returns this
*/
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.setMax = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Interval} returns this
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.clearMax = function() {
  return this.setMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.DecimalLimitation.Interval.prototype.hasMax = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Interval intervals = 1;
 * @return {!Array<!proto.dibric.graph.v1.DecimalLimitation.Interval>}
 */
proto.dibric.graph.v1.DecimalLimitation.prototype.getIntervalsList = function() {
  return /** @type{!Array<!proto.dibric.graph.v1.DecimalLimitation.Interval>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dibric.graph.v1.DecimalLimitation.Interval, 1));
};


/**
 * @param {!Array<!proto.dibric.graph.v1.DecimalLimitation.Interval>} value
 * @return {!proto.dibric.graph.v1.DecimalLimitation} returns this
*/
proto.dibric.graph.v1.DecimalLimitation.prototype.setIntervalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dibric.graph.v1.DecimalLimitation.Interval=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.DecimalLimitation.Interval}
 */
proto.dibric.graph.v1.DecimalLimitation.prototype.addIntervals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dibric.graph.v1.DecimalLimitation.Interval, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.graph.v1.DecimalLimitation} returns this
 */
proto.dibric.graph.v1.DecimalLimitation.prototype.clearIntervalsList = function() {
  return this.setIntervalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.DatetimeLimitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.DatetimeLimitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DatetimeLimitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    absoluteMin: (f = msg.getAbsoluteMin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    absoluteMax: (f = msg.getAbsoluteMax()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    relativeMin: (f = msg.getRelativeMin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    relativeMax: (f = msg.getRelativeMax()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.DatetimeLimitation}
 */
proto.dibric.graph.v1.DatetimeLimitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.DatetimeLimitation;
  return proto.dibric.graph.v1.DatetimeLimitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.DatetimeLimitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.DatetimeLimitation}
 */
proto.dibric.graph.v1.DatetimeLimitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAbsoluteMin(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAbsoluteMax(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelativeMin(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelativeMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.DatetimeLimitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.DatetimeLimitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.DatetimeLimitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbsoluteMin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAbsoluteMax();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRelativeMin();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRelativeMax();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp absolute_min = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.getAbsoluteMin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dibric.graph.v1.DatetimeLimitation} returns this
*/
proto.dibric.graph.v1.DatetimeLimitation.prototype.setAbsoluteMin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.DatetimeLimitation} returns this
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.clearAbsoluteMin = function() {
  return this.setAbsoluteMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.hasAbsoluteMin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp absolute_max = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.getAbsoluteMax = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dibric.graph.v1.DatetimeLimitation} returns this
*/
proto.dibric.graph.v1.DatetimeLimitation.prototype.setAbsoluteMax = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.DatetimeLimitation} returns this
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.clearAbsoluteMax = function() {
  return this.setAbsoluteMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.hasAbsoluteMax = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp relative_min = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.getRelativeMin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dibric.graph.v1.DatetimeLimitation} returns this
*/
proto.dibric.graph.v1.DatetimeLimitation.prototype.setRelativeMin = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.DatetimeLimitation} returns this
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.clearRelativeMin = function() {
  return this.setRelativeMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.hasRelativeMin = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp relative_max = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.getRelativeMax = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dibric.graph.v1.DatetimeLimitation} returns this
*/
proto.dibric.graph.v1.DatetimeLimitation.prototype.setRelativeMax = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dibric.graph.v1.DatetimeLimitation} returns this
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.clearRelativeMax = function() {
  return this.setRelativeMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dibric.graph.v1.DatetimeLimitation.prototype.hasRelativeMax = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dibric.graph.v1.StringComparison.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dibric.graph.v1.StringComparison.prototype.toObject = function(opt_includeInstance) {
  return proto.dibric.graph.v1.StringComparison.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dibric.graph.v1.StringComparison} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.StringComparison.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dibric.graph.v1.StringComparison}
 */
proto.dibric.graph.v1.StringComparison.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dibric.graph.v1.StringComparison;
  return proto.dibric.graph.v1.StringComparison.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dibric.graph.v1.StringComparison} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dibric.graph.v1.StringComparison}
 */
proto.dibric.graph.v1.StringComparison.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dibric.graph.v1.StringComparison.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dibric.graph.v1.StringComparison.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dibric.graph.v1.StringComparison.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dibric.graph.v1.StringComparison} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dibric.graph.v1.StringComparison.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dibric.graph.v1.StringComparison.Type = {
  UNKNOWN: 0,
  CASE_SENSITIVE: 1,
  CASE_INSENSITIVE: 2
};

/**
 * optional Type type = 1;
 * @return {!proto.dibric.graph.v1.StringComparison.Type}
 */
proto.dibric.graph.v1.StringComparison.prototype.getType = function() {
  return /** @type {!proto.dibric.graph.v1.StringComparison.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dibric.graph.v1.StringComparison.Type} value
 * @return {!proto.dibric.graph.v1.StringComparison} returns this
 */
proto.dibric.graph.v1.StringComparison.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string items = 2;
 * @return {!Array<string>}
 */
proto.dibric.graph.v1.StringComparison.prototype.getItemsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dibric.graph.v1.StringComparison} returns this
 */
proto.dibric.graph.v1.StringComparison.prototype.setItemsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dibric.graph.v1.StringComparison} returns this
 */
proto.dibric.graph.v1.StringComparison.prototype.addItems = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dibric.graph.v1.StringComparison} returns this
 */
proto.dibric.graph.v1.StringComparison.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * @enum {number}
 */
proto.dibric.graph.v1.NodeHandlePosition = {
  UNKNOWN: 0,
  TOP: 1,
  BOTTOM: 2,
  LEFT: 3,
  RIGHT: 4
};

/**
 * @enum {number}
 */
proto.dibric.graph.v1.HandleType = {
  UNKNOWN_HANDLE: 0,
  SUCCESS_HANDLE: 1,
  FAIL_HANDLE: 2
};

/**
 * @enum {number}
 */
proto.dibric.graph.v1.ValueType = {
  UNKNOWN_VALUE: 0,
  STRING: 1,
  DECIMAL: 2,
  INTEGER: 3,
  BOOLEAN: 4,
  DATETIME: 5,
  BINARY: 6
};

goog.object.extend(exports, proto.dibric.graph.v1);
